@use '../breakpoints/breakpoints.scss';


.container-notifications {
    z-index: 500;
    background-color: #fff;
    box-shadow: #ccc 0px 0px 15px;
    border-radius: 10px;
    // padding: 15px 20px;
    margin-top: 25px;
    display: flex;
    flex-direction: row;
    width: 350px;
    .header {
        padding: 15px;
        h2 {
            font-size: 16px;
            font-weight: 500;
            color: #023047;
            letter-spacing: 1px;
        }
        border-bottom: #ddd solid 1px;
    }
    .empty { 
        padding: 2rem 0;
        margin: 2rem 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        img {
            width: 100px;
            height: 100px;
        }
        p {
            padding: 15px;
            font-size: 13px;
            color: #023047;
            font-weight: 400;
            text-align: center;
        }
    }
    .content {
        height: calc(100vh - 50vh);
        overflow-y: auto;
        .unread {
            background-color: #e0e9f1 !important;
        }
        .list-item {
            cursor: pointer;
            padding: 10px;
            background-color: #fff;
            border-bottom: #ddd 1px solid;
            display: flex;
            img {
                width: 25px;
                height: 25px;
                margin-right: 10px;
            }
            p {
                font-size: 13px;
                color: #023047;
                font-weight: 400;
            }
            .title {
                margin: 0;
                padding: 0;
                font-size: 14px;
                font-weight: 400;
                color: #000;
                // text-transform: capitalize;
            }
            .time {
                padding-top: 2px;
                font-size: 12px;
                color: #023047;
                font-weight: 300;
            }
        }
    }
    .footer {
        padding: 14px 12px;
        background-color: #fff;
        border-radius: 10px;
        box-shadow: #ddd 0px 0px 5px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        p {
            cursor: pointer;
            font-size: 14px;
            color: #023047;
            font-weight: 500;
            text-align: center;
        }
    }
}

.notification-page {
    background-color: #fff;
    box-shadow: #ddd 0px 0px 10px;
    border-radius: 10px;
    max-height: calc(100vh - 200px);
    width: 100%;
    .empty { 
        padding: 3rem 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        img {
            width: 200px;
            height: 200px;
        }
        p {
            padding: 15px;
            font-size: 17px;
            color: #023047;
            font-weight: 400;
            text-align: center;
        }
    }
    .content {
        max-height: calc(100vh - 220px);
        overflow-y: auto;
        padding: 1rem;
        // padding-bottom: 1rem;
        .unread {
            background-color: #f3f8fc !important;
        }
        .list-item {
            cursor: pointer;
            padding: 15px 10px;
            background-color: #fff;
            border-bottom: #ddd 1px solid;
            display: flex;
            img {
                width: 25px;
                height: 25px;
                margin-right: 10px;
            }
            p {
                font-size: 13px;
                color: #023047;
                font-weight: 400;
            }
            .title {
                margin: 0;
                padding: 0;
                font-size: 14px;
                font-weight: 500;
                color: #023047;
            }
            .time {
                padding-top: 2px;
                font-size: 12px;
                color: #023047;
                font-weight: 300;
            }
        }
    }
}

.chat-container {
    height: calc(100vh - 200px);
    position: relative;
    width: 100%;
    margin: auto;
    background-color: #fff;
    box-shadow: #ddd 0px 0px 10px;
    border-radius: 10px;
}