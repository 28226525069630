

.header-background {
    background-color: #0091d6;
    display: flex;
    align-items: center;
    .logo-app {
        img {
            width: 110px;
        }
    }
    .hide-icon {
        padding-left: 2rem;
        .icon-icon-hide {
            color: #fff;
            width: 30;
            height: 30;
            cursor: pointer;
        }
    }
}

.logo-app-v1 {
    img {
        width: 30px;
    }
    margin-right: 1rem;
}

.unhide-icon {
    padding-left: 1rem;
    img {
        width: 25px;
        cursor: pointer;
    }
}

.right-navbar {
    color: #fff;
    border-left: #fff solid 2px;
    padding-left: 25px;
}

.text-navbar {
    a {
        color: #e8f0f5;
    }
}

.popper-style {
    margin-top: 20px;
}



.sidebar-navbar {
    margin-top: 1rem;
    ul {
        padding-left: 18px;
        margin: 8px 0;
        display: flex;
        border: none;
        li {
            span {
                color: #000;
                font-weight: 400;
                font-size: 14px;
            }
            padding: 1px;
        }
        .navbar-list {
            display: flex;
            align-items: center;
            .icon {
                margin-right: 10px;
                margin-top: 5px;
            }
            span {
                padding: 1px;
            }
        }
    }
    
    .active-link {
        ul {
            background-color: #86E5FF;
            margin: 10px 10px;
            padding-left: 9px;
            padding-right: 10px;
            border-radius: 8px;
            li {
                span {
                    color: #023263;
                    font-weight: 400;
                    font-size: 14px;
                }
                padding: 1px;
            }
            .navbar-list {
                display: flex;
                align-items: center;
                .icon {
                    margin-right: 5px;
                    margin-top: 5px;
                }
            }
            
        }
    }
        

    .sidebar-navbar-dropdown {
        padding-top: 5px;
        padding-bottom: 5px;
        font-size: 14px;
        .menu-dropdown {
            padding-left: 18px;
            padding-right: 27px;
            // padding-bottom: 5px;
            cursor: pointer;
            span {
                padding-left: 1px;
            }
        }
        .menu-list-dropdown {
            display: none;
        }
        .menu-list-dropdown-active {
            display: block;
            .navbar-list-child {
                padding-left: 15px;  
                display: flex;
                align-items: center;
                font-size: 12px;
                .icon {
                    color: #fff;
                    margin-right: 10px;
                    margin-top: 5px;
                    margin-left: 0px;
                }         
            }
        }
        .active-link-child {
            ul {
                background-color: #86E5FF;
                padding-right: 25px;
                padding-left: 7px;
                border-radius: 8px;
                margin-left: 25px !important;
                li {
                    span {
                        color: #023263;
                        font-weight: 400;
                        font-size: 14px;
                    }
                    padding: 1px;
                }
                .navbar-list-child {
                    padding-left: 0px;  
                    display: flex;
                    align-items: center;
                    font-size: 12px;
                    .icon {
                        color: #fff;
                        margin-right: 10px;
                        margin-top: 5px;
                        margin-left: 0px;
                    }         
                }
                
            }
        }
    }

    .active-link-child {
        ul {
            margin: 8px 17px;
            padding-left: 2px;
            background-color: #86E5FF;
            border-radius: 8px;
            li {
                span {
                    color: #023263;
                    font-weight: 400;
                    font-size: 13px;
                }
                padding: 1px;
            }
            .navbar-list-child {
                display: flex;
                align-items: center;
                font-size: 12px;
                .icon {
                    margin-right: 5px;
                    margin-top: 5px;
                }
            }
            
        }
    }
}

// .sidebar-navbar {
//     margin-top: 1rem;
//     ul {
//         padding-left: 19px;
//         margin: 8px 0;
//         display: flex;
//         border: none;
//         li {
//             span {
//                 color: #000;
//                 font-weight: 400;
//                 font-size: 14px;
//             }
//             padding: 1px;
//         }
//         .navbar-list {
//             display: flex;
//             align-items: center;
//             .icon {
//                 margin-right: 10px;
//                 margin-top: 5px;
//             }
//         }
//     }
// }

.icon-menu {
    color: #023047;
}

.icon-menu-child {
    color: #023047;
    width: 20px;
    height: 20px;
}

// .active-link {
//     ul {
//         margin: 8px 10px;
//         padding-left: 10px;
//         // border: #0091d6 2px solid;
//         background-color: #86E5FF;
//         border-radius: 8px;
//         li {
//             span {
//                 color: #023263;
//                 font-weight: 400;
//                 font-size: 14px;
//             }
//             padding: 1px;
//         }
//         .navbar-list {
//             display: flex;
//             align-items: center;
//             .icon {
//                 margin-right: 10px;
//                 margin-top: 5px;
//             }
//         }
        
//     }
// }
    

// .sidebar-navbar-dropdown {
//     padding-top: 15px;
//     font-size: 14px;
//     .menu-dropdown {
//         padding-left: 20px;
//         padding-right: 27px;
//         // padding-bottom: 5px;
//         cursor: pointer;
//     }
//     .menu-list-dropdown {
//         display: none;
//     }
//     .menu-list-dropdown-active {
//         display: block;
//         .navbar-list-child {
//             padding-left: 2px;  
//             display: flex;
//             align-items: center;
//             font-size: 12px;
//             .icon {
//                 color: #fff;
//                 margin-right: 10px;
//                 margin-top: 5px;;
//             }         
//         }
//     }
// }

// .active-link-child {
//     ul {
//         margin: 8px 17px;
//         padding-left: 2px;
//         background-color: #86E5FF;
//         border-radius: 8px;
//         li {
//             span {
//                 color: #023263;
//                 font-weight: 400;
//                 font-size: 13px;
//             }
//             padding: 1px;
//         }
//         .navbar-list-child {
//             display: flex;
//             align-items: center;
//             font-size: 12px;
//             .icon {
//                 margin-right: 5px;
//                 margin-top: 5px;
//             }
//         }
        
//     }
// }

.connected-box {
    border: 1px solid #fff;
    border-radius: 4px;
    padding: 4px 20px;
    font-size: 14px;
}

.disconnected-box {
    border: 1px solid red;
    border-radius: 4px;
    padding: 4px 20px;
    font-size: 14px;
}


.container-profile {
    background-color: #fff;
    box-shadow: #ccc 0px 0px 5px;
    border-radius: 8px;
    padding: 15px 20px;
    margin-top: 7px;
    margin-right: 20px;
    min-width: 250px;
    min-height: 200px;
    .header-profile {
        display: flex;
        align-items: center;
        margin-bottom: 1rem;
        .header-profile-name {
            padding-left: 1rem;
            h5 {
                font-size: 16px;
                font-weight: 500;
                color: #000;
                margin: 0;
                padding: 0;
            }
            p {
                font-size: 12px;
                font-weight: 500;
                color: #000;
                margin: 0;
                padding: 0;
            }
        }
    }
    .menu-list {
        padding: 6px 0;
        display: flex;
        align-items: center;
        cursor: pointer;
        .icon-menus {
            margin-right: 7px;
            color: #023047;
            width: 25px;
            height: 25px;
        }
        h4 {
            font-size: 14px;
            font-weight: 500;
            color: #000;
        }
    }
}