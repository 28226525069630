@use '../breakpoints/breakpoints.scss';

.team-container {
    margin: 5px 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .team-item-add {
        min-height: 260px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        border-radius: 10px;
        padding: 1rem 10px;
        box-shadow: #ddd 0 0 10px;
        margin: 1px;
        text-align: center;
        h1 {
            font-size: 1.2rem;
            font-weight: 500;
            margin: 0;
            padding-bottom: 0.5rem;
        }
        p {
            font-size: 0.8rem;
            margin: 0;
            font-weight: 400;
        }
        img {
            margin-bottom: 1rem;
            width: 100px;
            height: 100px;
            object-fit: cover;
        }
    }
    .team-ownwer {
        box-shadow: #0091d6 0 0 5px !important;
    }
    .team-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        border-radius: 10px;
        padding: 1rem 10px;
        box-shadow: #ccc 0 0 5px;
        margin: 1px;
        .team-item__image {
            padding-top: 1rem;
        }
        .team-item__name {
            padding: 10px 0;
            text-align: center;
            h1 {
                font-size: 1.2rem;
                font-weight: 500;
                margin: 0;
                padding-bottom: 0.5rem;
            }
            p {
                font-size: 0.8rem;
                margin: 0;
                font-weight: 400;
            }
        }
        .team-item__role {
            p {
                font-size: 1rem;
                margin: 0;
                font-weight: 600;
            }
        }
        .team-item__action {
            padding-top: 2rem;
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
        }
    }
}

.create-team {
    display: flex;
    justify-content: center;
    .form-auth {
        margin: 1rem 0;
        width: 400px;
        min-height: 500px;
        h2 {
            text-align: left;
            font-size: 15px;
            color: #000;
            font-weight: 400;
            letter-spacing: 0.5px;
        }
        .button-auth {
            margin: 15px 0;
            button {
                cursor: pointer;
                background-color: #43a047;
                border: #fff solid 1px;
                box-shadow: #ddd 0px 0px 10px;
                border-radius: 9px;
                padding: 10px 17px;
                width: 100%;
                text-align: center;
                transition: all 0.3s ease;
                color: #fff;
                font-size: 16px;
                font-weight: 500;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            :disabled {
                background-color: #ddd;
                color: rgb(168, 165, 165);
                cursor: not-allowed;
                border: none;
                outline: none;
            }
        }
        .button-auth:hover {
            button {
                cursor: pointer;
                background-color: #138f19;
                border: #fff solid 1px;
                box-shadow: #ddd 0px 0px 10px;
                border-radius: 9px;
                padding: 10px 17px;
                width: 100%;
                text-align: center;
                color: #fff;
                font-size: 16px;
                font-weight: 500;
            }
            :disabled {
                background-color: #ddd;
                color: rgb(168, 165, 165);
                cursor: not-allowed;
                border: none;
                outline: none;
            }
        }
    }
    @include breakpoints.media('<=phone') {
        .form-auth {
            margin-top: 1rem;
            width: 280px;
        }
        .name-login {
            h1 {
                font-size: 23px;
            }
            p {
                font-size: 15px;
            }
        }
    }
    @include breakpoints.media('>phone', '<=tablet') {
        .form-auth {
            margin-top: 1rem;
            width: 280px;
        }
        .name-login {
            h1 {
                font-size: 24px;
            }
            p {
                font-size: 15px;
            }
        }
    }
}



.roles-container {
    margin: 5px 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .roles-card {
        background-color: #fff;
        border-radius: 10px;
        padding: 1rem 10px;
        box-shadow: #ddd 0 0 10px;
        .roles-item {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-around;
            margin: 1px;
            .roles-item__image {
                img {
                    width: 70px;
                    height: 70px;
                    object-fit: cover;
                }
            }
            .roles-item__name {
                padding: 5px 0;
                text-align: center;
                h1 {
                    font-size: 1.2rem;
                    font-weight: bold;
                    margin: 0;
                }
                p {
                    font-size: 0.8rem;
                    margin: 0;
                    font-weight: 300;
                    color: #1b4559;
                    padding-top: 2px;
                }
            }
            .roles-item__role {
                p {
                    font-size: 0.8rem;
                    margin: 0;
                    font-weight: 600;
                }
            }
        }
        .roles-item__action {
            padding-top: 1rem;
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
        }
    }
    }


.extension-container {
    margin: 5px 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .extension-card {
        background-color: #fff;
        border-radius: 10px;
        padding: 1rem 10px;
        box-shadow: #ddd 0 0 10px;
        cursor: pointer;
        .extension-item {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 1px;
            .used {
                align-self: self-start;
                cursor: default;
                color: #138f19;
            }
            .extension-item__image {
                img {
                    width: 100px;
                    height: 100px;
                    object-fit: cover;
                }
            }
            .extension-item__name {
                margin-left: 10px;
                h1 {
                    font-size: 1.2rem;
                    font-weight: bold;
                    line-height: 0.9;
                    margin: 0;
                    padding: 0;
                }
                h6 {
                    font-size: 0.8rem;
                    font-weight: 300;
                    margin: 0;
                }
                p {
                    font-size: 0.8rem;
                    margin: 0;
                    font-weight: 300;
                    color: #1b4559;
                }
                a {
                    font-size: 0.8rem;
                    font-weight: 300;
                    color: #0400f3;
                    cursor: pointer;
                    margin-bottom: 2px;
                }
            }
            .extension-item__role {
                p {
                    font-size: 0.8rem;
                    margin: 0;
                    font-weight: 600;
                }
            }
        }
        .extension-item__action {
            padding-top: 1rem;
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
        }
    }
}


.extension-dialog {
    margin: 5px 0;
    .extension-card {
        .extension-item {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 1px;
            .extension-item__image {
                img {
                    width: 80px;
                    height: 80px;
                    object-fit: cover;
                }
            }
            .extension-item__name {
                margin-left: 10px;
                h1 {
                    font-size: 1.2rem;
                    font-weight: bold;
                    line-height: 0.9;
                    margin: 0;
                    padding: 0;
                }
                h6 {
                    font-size: 0.8rem;
                    font-weight: 300;
                    margin: 0;
                }
                a {
                    font-size: 0.8rem;
                    font-weight: 300;
                    color: #0400f3;
                    cursor: pointer;
                    margin-bottom: 2px;
                }
            }
        }
    }
    h2 {
        font-size: 1.3rem;
        font-weight: bold;
        line-height: 0.9;
        margin: 0;
        padding: 0;
    }
}

    
.extension-int-container {
    margin: 5px 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
    .extension-int-card {
        border-radius: 10px;
        padding: 1rem 10px;
        box-shadow: #ddd 0 0 10px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .extension-int-item {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 1px;
            .extension-int-item__image {
                img {
                    width: 50px;
                    height: 50px;
                    object-fit: cover;
                }
            }
            .extension-int-item__name {
                margin-left: 10px;
                h1 {
                    font-size: 1.2rem;
                    font-weight: bold;
                    line-height: 0.9;
                    margin: 0;
                    padding: 0;
                }
                h6 {
                    font-size: 0.8rem;
                    font-weight: 300;
                    margin: 0;
                }
                p {
                    font-size: 0.8rem;
                    margin: 0;
                    font-weight: 300;
                    color: #1b4559;
                }
                a {
                    font-size: 0.8rem;
                    font-weight: 300;
                    color: #0400f3;
                    cursor: pointer;
                    margin-bottom: 2px;
                }
            }
            .extension-int-item__role {
                p {
                    font-size: 0.8rem;
                    margin: 0;
                    font-weight: 600;
                }
            }
        }
        .extension-int-item__action {
            display: flex;
            column-gap: 0.7rem;
        }
    }
}
    
    .tabs-extensions {
        border-bottom: 1px solid #ddd;
        display: flex;
        flex-direction: row;
        column-gap: 20px;
        .tabs {
            h2 {
                font-size: 1rem;
                font-weight: 500;
                margin: 0;
                padding: 0;
                cursor: pointer;
                padding: 5px 0;
            }
        }
        .tabs-active  {
            h2 {
                font-size: 1rem;
                font-weight: 500;
                margin: 0;
                padding: 0;
                color: #0091d6;
                cursor: pointer;
                padding: 5px 0;
                border-bottom: 2px solid #0091d6;
            }
        }
    }