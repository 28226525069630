.paper-box {
    width: 200px;
    height: 100px;
    background-color: red;
    // .box-div {
    //     margin-right: 10px;
    // }
}

.rdt_TableHeadRow {
	border-radius: 5px;
}

.rdt_TableCol {
    font-weight: 500;
    color: azure;
    font-size: 15px;
	text-transform: capitalize;
    background-color: #0091d6;
	color: #fff;
    height: 50px;
	// margin-bottom: 5px;
}

.rdt_TableCell {
    height: 45px;
    font-size: 12px;
}

.jsAMOb {
	height: 70px !important;
	background-color: red;
}


.img-notfound {
    margin-top: 2rem;
    img {
        width: 500px;
    }
	text-align: center;
	h5 {
		font-weight: 400;
		color: #000;
		font-size: medium;
	}
}

.text-notfound {
    padding-top: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    h1 {
        margin-bottom: 20px;
    }
}

.card-statistic {
	h4 {
		font-weight: 300;
		color: #000;
		font-family: 'Roboto', sans-serif;
		letter-spacing: 1px;
	}
    h3 {
        font-weight: 400;
		font-size: 25px;
		color: #0091d6;
		padding-top: 5px;
    }
	img {
		width: 50px;
	}
	display: flex;
	justify-content: space-between;
}

.card-statistic-status {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: #000;
    h4 {
        font-weight: 500;
    }
    h1 {
        font-size: 30px;
    }
}

.loading-button {
    display: flex;
    align-items: center;
    p {
        margin-right: 5px;
    }
}

.menu-pop-over {
    margin-top: 5px;
    .box-menu {
        max-width: 500px;
		max-height: 470px;
        list-style: none;
        padding: 10px;
        margin: 0;
    }
}

.paper-table-product {
	width: 82vw;
}

.btn-icon-upload {
	border: 2px dotted #ccc;
	padding: 10px; 
	cursor: pointer;
	text-align: center;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}


.box-images-btn {
	cursor: pointer;
	border: #ccc solid 2px;
	width: 131px;
	height: 117px;
	border-radius: 5px;
	border-style: dotted;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	img {
		width: 45px;
	}
	p {
		font-size: 10px;
		cursor: pointer;
	}
}

.image-preview {
	position: relative;
	cursor: pointer;
	background-color: #f5f5f5;
	border-radius: 5px;
	width: 131px;
	img {
		width: 125px;
		height: 125px;
		padding: 5px;
	}
	.btn-remove-img {
		position: absolute;
		right: 0;
		display: none;
		color: #ee4054;
	}
}


.image-preview:hover {
	position: relative;
	.btn-remove-img{
		position: absolute;
		bottom: 0;
		color: #f91204;
		display: block;
	}

}

.warehouse-preview {
	border: #afc0c9 1px solid;
	border-radius: 10px;
	padding: 15px;
	img {
		width: 45px;
	}
}
.box-warehouse {
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 20px;
	border: 1px solid #cccccc;
	border-radius: 5px;
	position: relative;
	.icon-remove {
		position: absolute;
		right: 0;
		top: 0;
		padding: 10px;
		color: #e8505b;
		cursor: pointer;
	}
}

.box-empty-warehouse {
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 20px;
	border: 2px solid #cccccc;
	border-radius: 5px;
	position: relative;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	img {
		width: 100px;
		height: 100px;
	}
}

.upload-area-bulk {
	margin-top: 5px;
	cursor: pointer;
	height: 200px;
	border: #3db39e dashed 2px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	border-radius: 5px;
	img {
		width: 60px;
	}
}

.file-area-bulk {
	padding: 10px;
	height: 200px;
	border: #0091d6 solid 1px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	border-radius: 5px;
	img {
		width: 50px;
		padding-bottom: 10px;
	}
}

.icon-button {
	width: 30px;
	height: 30px;
	cursor: pointer;
}


.img-error-status {
    margin-top: 2rem;
    img {
        width: 300px
    }
	text-align: center;
	h5 {
		font-size: large;
		font-weight: 300;
		color: #000;
		font-size: 17px;
		padding-top: 10px;
	}
	h6 {
		font-weight: 300;
		color: #000;
		font-size: 15px;
		padding-top: 10px;
	}
	h1 {
		color: red;
		font-size: 22px;
		padding-top: 10px;
	}
}

.color-yellow {
	color: #d80d0d !important;
}

.color-red {
	color: red !important;
	font-weight: 400;
}

.text-notfound-status {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    h1 {
        margin-bottom: 20px;
    }
}


.box-bank {
	// border: 1px solid #cccccc;
	// border-radius: 10px;
	// padding: 5px;
	display: flex;
	justify-content: space-between;
	img {
		width: 100px;
		height: 100px;
		border-radius: 10px;
		margin-right: 10px;
	}
	.bank-label {
		font-size: 18px;
		padding-bottom: 5px;
		font-weight: 500;
	}
	.bank-name {
		font-size: 15px;
		font-weight: 400;
	}
}

.link-file-name {
	color: #000;
	// text-transform: capitalize;
	padding-left: 3px;
}

.modules-items {
	border-radius: 10px;
	box-shadow: #ddd 0 0 10px;
	max-height: calc(100vh - 200px);
	overflow-y: auto;
}

.modules-items-second {
	min-height: calc(100vh - 40vh);
	padding-top: 15px;
	.box-schedule {
		// background-color: rgb(160, 157, 157);
		box-shadow: #ddd 0 0 10px;
		margin-top: 10px;
		margin-bottom: 10px;
		border-radius: 10px;
		padding: 15px;
		display: flex;
		flex-wrap: wrap;
		row-gap: 10px;
		column-gap: 10px;
		align-items: center;
		h4 {
			padding-bottom: 5px;
		}
	}
}
.contact {
	h3 {
		font-size: 16px;
		font-weight: 500;
		color: #000;
	}
	.content-contact {
		padding-left: 10px;
		padding-top: 10px;
		padding-bottom: 10px;
		h4 {
			font-size: 14px;
			font-weight: 300;
			color: #000;
		}
		.phone {
			display: flex;
			flex-direction: row;
			padding-bottom: 5px;
			p {
				font-size: 14px;
				font-weight: 300;
				color: #000;
				padding-left: 0.5rem;
			}
		}
		.address {
			p {
				font-size: 14px;
				font-weight: 300;
				color: #000;
				padding-left: 0.5rem;
			}
		}
	}
}

#customzindex {
	z-index: 1500 !important;
}
// phone code input 
/* CSS variables. */
:root {
	--PhoneInput-color--focus: #03b2cb;
	--PhoneInputInternationalIconPhone-opacity: 0.8;
	--PhoneInputInternationalIconGlobe-opacity: 0.65;
	--PhoneInputCountrySelect-marginRight: 0.35em;
	--PhoneInputCountrySelectArrow-width: 0.3em;
	--PhoneInputCountrySelectArrow-marginLeft: var(--PhoneInputCountrySelect-marginRight);
	--PhoneInputCountrySelectArrow-borderWidth: 1px;
	--PhoneInputCountrySelectArrow-opacity: 0.45;
	--PhoneInputCountrySelectArrow-color: inherit;
	--PhoneInputCountrySelectArrow-color--focus: var(--PhoneInput-color--focus);
	--PhoneInputCountrySelectArrow-transform: rotate(45deg);
	--PhoneInputCountryFlag-aspectRatio: 1.5;
	--PhoneInputCountryFlag-height: 1em;
	--PhoneInputCountryFlag-borderWidth: 1px;
	--PhoneInputCountryFlag-borderColor: rgba(0,0,0,0.5);
	--PhoneInputCountryFlag-borderColor--focus: var(--PhoneInput-color--focus);
	--PhoneInputCountryFlag-backgroundColor--loading: rgba(0,0,0,0.1);
}

.PhoneInput {
	/* This is done to stretch the contents of this component. */
	display: flex;
	align-items: center;
    height: 50px;
}

.PhoneInputInput {
	/* The phone number input stretches to fill all empty space */
	flex: 1;
	/* The phone number input should shrink
	   to make room for the extension input */
	min-width: 0;
    height: 40px;
    border: 1px solid #ccc;
    font-size: 15px;
    padding-left: 10px;
    margin-left: 5px;
}

.PhoneInputCountryIcon {
	width: calc(var(--PhoneInputCountryFlag-height) * var(--PhoneInputCountryFlag-aspectRatio));
	height: var(--PhoneInputCountryFlag-height);
    margin-right: 7px;
}

.PhoneInputCountryIcon--square {
	width: var(--PhoneInputCountryFlag-height);
}

.PhoneInputCountryIcon--border {
	/* Removed `background-color` because when an `<img/>` was still loading
	   it would show a dark gray rectangle. */
	/* For some reason the `<img/>` is not stretched to 100% width and height
	   and sometime there can be seen white pixels of the background at top and bottom. */
	background-color: var(--PhoneInputCountryFlag-backgroundColor--loading);
	/* Border is added via `box-shadow` because `border` interferes with `width`/`height`. */
	/* For some reason the `<img/>` is not stretched to 100% width and height
	   and sometime there can be seen white pixels of the background at top and bottom,
	   so an additional "inset" border is added. */
	box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor),
		inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor);
}

.PhoneInputCountryIconImg {
	/* Fixes weird vertical space above the flag icon. */
	/* https://gitlab.com/catamphetamine/react-phone-number-input/-/issues/7#note_348586559 */
	display: block;
	/* 3rd party <SVG/> flag icons won't stretch if they have `width` and `height`.
	   Also, if an <SVG/> icon's aspect ratio was different, it wouldn't fit too. */
	width: 100%;
	height: 100%;
}

.PhoneInputInternationalIconPhone {
	opacity: var(--PhoneInputInternationalIconPhone-opacity);
}

.PhoneInputInternationalIconGlobe {
	opacity: var(--PhoneInputInternationalIconGlobe-opacity);
}

/* Styling native country `<select/>`. */

.PhoneInputCountry {
	position: relative;
	align-self: stretch;
	display: flex;
	align-items: center;
	margin-right: var(--PhoneInputCountrySelect-marginRight);
}

.PhoneInputCountrySelect {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 1;
	border: 0;
	opacity: 0;
	cursor: pointer;
}

.PhoneInputCountrySelect[disabled] {
	cursor: default;
}

.PhoneInputCountrySelectArrow {
	display: block;
	content: '';
	width: 12px;
	height: 12px;
    color: #000;
	margin-left: 2px;
	border-style: solid;
	border-color: #000;
	border-top-width: 0;
	border-bottom-width: var(--PhoneInputCountrySelectArrow-borderWidth);
	border-left-width: 0;
	border-right-width: var(--PhoneInputCountrySelectArrow-borderWidth);
	transform: var(--PhoneInputCountrySelectArrow-transform);
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon + .PhoneInputCountrySelectArrow {
	opacity: 1;
	color: var(--PhoneInputCountrySelectArrow-color--focus);
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon--border {
	box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor--focus),
		inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor--focus);
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon .PhoneInputInternationalIconGlobe {
	opacity: 1;
	color: var(--PhoneInputCountrySelectArrow-color--focus);
}

.container-cattree {
	position: relative;
}

.input-select-tree {
	display: flex;
	width: 100%;
	height: 40px;
	border-radius: 5px;
	border: 1px solid #ccc;
	padding-left: 10px;
	padding-right: 10px;
	cursor: pointer;
	justify-content: space-between;
}

.icon-tree {
	padding-top: 5px;
}

.input-select-tree > p {
	padding-top: 8px;
	border: none;
}

.content-catree {
	background-color: #fff;
	border-bottom: 1px solid #ccc;
	border-left: 1px solid #ccc;
	border-right: 1px solid #ccc;
	padding: 10px;
	z-index: 200;
	position: absolute;
	width: 100%;
	height: 230px;
	display: flex;
	padding-bottom: 20px;
}

.box-tree {
	display: flex;
	justify-content: space-between;
	cursor: pointer;
	width: 100%;
	padding-right: 5px;
	// border: 1px solid red;
}

.content-box {
	border-right: 1px solid #ccc;
	// border-left: 1px solid #ccc;
	overflow-y: auto;
	margin-left: 5px;
	// margin-right: 5px;

}

.box-tree > p {
	padding-left: 5px;
}
.content-box-item {
	padding: 2px;
	margin-bottom: 2px;
}

.active-tree {
	border-left: 2px solid #0091d6;
}

.content-box-item:hover {
	background-color: #bbdefb;
	border-radius: 5px;
}

.box-tree-selected {
	display: flex;
	flex-direction: row;
}

.title-tree-selected {
	display: flex;
	flex-direction: row;
	padding-top: 8px;
}

.title-tree-selected > p {
	padding-right: 5px;
}

.title-tree-selected > span {
	padding-right: 5px;
}


.button-create {
	button {
		cursor: pointer;
		background-color: #0091d6;
		border: #fff solid 1px;
		box-shadow: #ddd 0px 0px 10px;
		border-radius: 9px;
		padding: 7px 17px;
		width: 100%;
		text-align: center;
		transition: all 0.3s ease;
		color: #fff;
		font-size: 14px;
		font-weight: 500;
	}
	:disabled {
		background-color: #ddd;
		color: rgb(168, 165, 165);
		cursor: not-allowed;
		border: none;
		outline: none;
	}
}
.button-create:hover {
	button {
		cursor: pointer;
		background-color: #0f80b4;
		border: #fff solid 1px;
		box-shadow: #ddd 0px 0px 10px;
		border-radius: 9px;
		padding: 7px 17px;
		width: 100%;
		text-align: center;
		color: #fff;
		font-size: 14px;
		font-weight: 500;
	}
	:disabled {
		background-color: #ddd;
		color: rgb(168, 165, 165);
		cursor: not-allowed;
		border: none;
		outline: none;
	}
}



.button-create-outlined {
	button {
		cursor: pointer;
		background-color: #fff;
		border: #0091d6 solid 1px;
		// box-shadow: #0091d6 0px 0px 10px;
		border-radius: 9px;
		padding: 7px 17px;
		width: 100%;
		text-align: center;
		transition: all 0.3s ease;
		color: #0091d6;
		font-size: 14px;
		font-weight: 500;
	}
	:disabled {
		background-color: #ddd;
		color: rgb(168, 165, 165);
		cursor: not-allowed;
		border: none;
		outline: none;
	}
}
.button-create-outlined:hover {
	button {
		cursor: pointer;
		background-color: #0f80b4;
		border: #fff solid 1px;
		box-shadow: #ddd 0px 0px 10px;
		border-radius: 9px;
		padding: 7px 17px;
		width: 100%;
		text-align: center;
		color: #fff;
		font-size: 14px;
		font-weight: 500;
	}
	:disabled {
		background-color: #ddd;
		color: rgb(168, 165, 165);
		cursor: not-allowed;
		border: none;
		outline: none;
	}
}


.overhiddenflow {
    height: calc(100vh - 300px);
    overflow: auto;
}

.floating-button {
    // position: absolute;
    font-size: 14px;
    bottom: 0;
    left: 0;
    margin-top: 30px;
    margin-bottom: 10px;
}

.date-select {
    background-color: #fff;
    box-shadow: #ccc 0px 0px 12px;
    padding: 15px;
    border-radius: 7px;
    margin-top: 5px;
    .divider-btn {
        border-top: #ccc solid 1px;
        padding-top: 10px;
        display: flex;
        justify-content: flex-end;
    }
}



.btn_error {
	button {
		background-color: #e8505b !important;
	}
}

.btn_error:hover {
	button {
		background-color: #be0915 !important;
	}
}

.btn_outlined {
	button {
		background-color: #fff !important;
		border: #0091d6 solid 1px !important;
		color: #0091d6 !important;
	}
	:disabled {
		background-color: #ddd !important;
		border: #ddd solid 1px !important;
		color: rgb(133, 133, 133) !important;
		color: rgb(168, 165, 165);
		cursor: not-allowed;
		border: none;
		outline: none;
	}
}
.button-actions {
	button {
		cursor: pointer;
		background-color: #0091d6;
		border: #fff solid 1px;
		box-shadow: #ddd 0px 0px 10px;
		border-radius: 11px;
		padding: 7px 17px;
		width: 100%;
		text-align: center;
		transition: all 0.3s ease;
		color: #fff;
		font-size: 13px;
		font-weight: 400;
	}
	:disabled {
		background-color: #ddd;
		color: rgb(168, 165, 165);
		cursor: not-allowed;
		border: none;
		outline: none;
	}
}
.button-actions:hover {
	button {
		cursor: pointer;
		background-color: #0b6b97;
		border: #fff solid 1px;
		box-shadow: #ddd 0px 0px 10px;
		border-radius: 11px;
		padding: 7px 17px;
		width: 100%;
		text-align: center;
		color: #fff;
		font-size: 13px;
		font-weight: 400;
	}
	:disabled {
		background-color: #ddd;
		color: rgb(168, 165, 165);
		cursor: not-allowed;
		border: none;
		outline: none;
	}
}


#flex-date {
	display: flex;
	align-items: center;
	p {
		font-size: 14px;
		font-weight: 500;
		margin: 0;
	}
}


.dashboard-status-order {
	margin-top: 2rem;
	border: #fff solid 1px;
	box-shadow: #ddd 0px 0px 10px;
	border-radius: 8px;
	padding: 15px;
	h2 {
		font-size: 17px;
		font-weight: 500;
		margin: 0;
		padding-bottom: 0.7rem;
	}
	.card-status {
		padding: 10px;
		border-radius: 8px;
		display: flex;
		align-items: center;
		// margin: 5px 0;
		h3 {
			text-align: center;
			font-size: 16px;
			font-weight: 500;
			margin: 0;
			color: #036b9b;
			z-index: 1000;
			position: relative;
		}
		.exist {
			background: #e07272 !important; 
		}
		.tv-screen {
			cursor: pointer;
			position: relative;
			width: 60px;
			height: 50px;
			background: #ccd8dd;
			border-radius: 50% / 10%;
			text-align: center;
			display: flex;
			align-items: center;
			justify-content: center;
			h4 {
				text-align: center;
				font-size: 20px;
				font-weight: 400;
				margin: 0;
				color: #fffeff;
				z-index: 1000;
				position: relative;
			}
			margin-right: 1rem;
		}
		  
		.tv-screen:before {
			content: '';
			position: absolute;
			top: 10%;
			bottom: 10%;
			right: -5%;
			left: -5%;
			background: inherit;
			border-radius: 5% / 50%;
		}
	}
}



.card-six-tiles {
	background-color: #f4f4f4;
    // border: #fff solid 1px;
    box-shadow: #ddd 0px 0px 10px;
    border-radius: 1px;
    padding: 10px ;
}

.card-three-tiles {
	background-color: #f4f4f4;
    // border: #fff solid 1px;
    box-shadow: #ddd 0px 0px 10px;
    border-radius: 10px;
    padding: 5px;
}

.card-lines-paper {
	background-color: #f4f4f4;
    box-shadow: #ddd 0px 0px 10px;
    border-radius: 1px;
    padding: 10px ;
	h2 {
		font-size: 1.2rem;
		font-weight: 500;
		margin: 0;
		text-align: center;
		padding-top: 5px;
		color: #0091d6;
	}
}

.card-seller-sales {
	background-color: #f4f4f4;
    box-shadow: #ddd 0px 0px 10px;
    border-radius: 1px;
    padding: 10px ;
	h2 {
		font-size: 1.2rem;
		font-weight: 500;
		margin: 0;
		text-align: center;
		padding-top: 5px;
		color: #0091d6;
	}
}

.table-card {
	background-color: #fff;
    box-shadow: #fff 0px 0px 10px;
    border-radius: 8px;
    padding: 5px ;
	margin: 0 10px;
}

.card-brand-sales {
	background-color: #f4f4f4;
    box-shadow: #ddd 0px 0px 10px;
    border-radius: 1px;
    padding: 10px ;
	h2 {
		font-size: 1.2rem;
		font-weight: 500;
		margin: 0;
		text-align: center;
		padding-top: 5px;
		color: #0091d6;
	}
}



.tiles-paper {
	background-color: #fff;
    border: #f4f4f4 solid 1px;
    box-shadow: #f4f4f4 0px 0px 5px;
    border-radius: 10px;
    padding: 19px;
    
    .tiles-info {
        h4 {
            font-weight: 400;
            color: #000;
            font-size: 14px;
			padding-bottom: 5px;
        }
        h3 {
            font-weight: 500;
            font-size: 18px;
            color: #0091d6;
        }
		h1 {
			font-weight: bold;
            font-size: 25px;
            color: #0091d6;
		}
        display: flex;
        justify-content: space-between;
    }
}


.purchase-paper {
    background-color: #fff;
    border: #fff solid 1px;
    box-shadow: #ddd 0px 0px 10px;
    border-radius: 10px;
    padding: 4px;
	margin: 5px;
    
    .purchase-info {
        h4 {
            font-weight: 400;
            color: #000;
            font-size: 13px;
			padding-bottom: 2px;
        }
        h3 {
            font-weight: 500;
            font-size: 17px;
            color: #0091d6;
        }
        img {
            width: 35px;
            height: 35px;
        }
        display: flex;
        justify-content: space-between;
    }
}

.filter-date-paper {
	background-color: #fff;
    border: #fff solid 1px;
    box-shadow: #ddd 0px 0px 10px;
    border-radius: 10px;
    padding: 5px 10px;
	cursor: pointer;
}

.selection-box-title {
	min-width: 200px;
	background-color: #fff;
	box-shadow: #ddd 0px 0px 10px;
	padding: 5px 15px;
	border-radius: 10px;
	margin-right: 10px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
	img {
		width: 18px;
		height: 18px;
		padding-right: 5px;
	}
	p {
		text-transform: capitalize;
	}
}

.container-hover {
    z-index: 500;
    background-color: #fff;
    box-shadow: #ddd 0px 0px 10px;
    border-radius: 5px;
    padding: 10px;
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    min-width: 179px;
    .list-menu {
        padding: 7px 10px;
        cursor: pointer;
        margin: 5px 0;
    }
    .list-menu:hover {
        padding: 7px 10px;
        border-radius: 5px;
        background-color: #364153;
        cursor: pointer;
        margin: 5px 0;
        p {
            color: #fff;
        }
    }
    .list-menu-active {
        padding: 7px 10px;
        border-radius: 5px;
        background-color: #364153;
        cursor: pointer;
        margin: 5px 0;
        p {
            color: #fff;
        }
    }
}

.print-btn-pdfdownload {
	margin: 0 5px;
	display: flex;
	cursor: pointer;
	align-items: center;
	border: #0091d6 1px solid;
	transition: all .2s ease-in;
	border-radius: 5px;
	padding: 3px 10px;
	p {
		font-size: 14px;
		color: #0091d6;
		font-weight: 400;
	}
}
.print-btn-pdfdownload:hover{
	display: flex;
	cursor: pointer;
	align-items: center;
	background-color: #0091d6;
	border-radius: 10px;
	padding: 3px 10px;
	p {
		font-size: 14px;
		color: #fff;
		font-weight: 400;
	}
}


.select-warehouse-product {
	margin-top: 10px;
	background-color: #fff;
	box-shadow: #ddd 0px 0px 10px;
    border-radius: 10px;
	padding: 16px;
}



.filter-component {
	border: 2px solid #ccc;
	border-radius: 10px;
	padding: 5px 10px;
	text-align: center;
	cursor: pointer;
}

.filter-content {
    background-color: #fff;
    box-shadow: #ccc 0px 0px 12px;
    padding: 10px;
    border-radius: 7px;
    margin-top: 5px;
	.divider-btn {
        border-top: #ccc solid 1px;
        padding-top: 10px;
        display: flex;
        justify-content: flex-end;
    }
	.filter-checkbox {
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
		row-gap: 0.5rem;
		column-gap: 0.5rem;
		align-items: center;
		font-family: 'Roboto', sans-serif;
	}
}