
@use '../breakpoints/breakpoints.scss';


.card-paper {
    background-color: #fff;
    border: #fff solid 1px;
    box-shadow: #ddd 0px 0px 10px;
    border-radius: 10px;
    padding: 15px;
    
    .card-info {
        h4 {
            font-weight: 400;
            color: #000;
            font-size: 14px;
        }
        h3 {
            font-weight: 500;
            font-size: 20px;
            color: #0091d6;
        }
        img {
            width: 40px;
            height: 40px;
        }
        display: flex;
        justify-content: space-between;
    }
}



.container-search-invoice {
    margin-top: 10px;
    background-color: #fff;
    border: #ddd solid 1px;
    box-shadow: #ddd 0px 0px 15px;
    border-radius: 10px;
    padding: 15px;
    .status {
        display: flex; 
        flex-direction: row;
        align-items: center;
        padding-top: 15px;
        h5 {
            font-size: 15px;
            font-weight: 500;
            color: #000;
            text-transform: capitalize;
        }
        .list-status {
            padding-left: 10px;
            display: flex;
            flex-wrap: wrap;
            .container-status {
                position:relative;
                padding-top:10px;
                display:inline-block;
                margin: 5px 10px;
                .status-box {
                    // background-color: #fff;
                    border: #ddd solid 1px;
                    box-shadow: #ddd 0px 0px 10px;
                    padding: 5px 15px;
                    border-radius: 10px;
                    cursor: pointer;
                    h6 {
                        font-size: 16px;
                        font-weight: 400;
                        color: #092c4c;
                        text-transform: capitalize;
                        margin: 0;
                    }
                }
                @include breakpoints.media('>phone', '<=tablet') {
                    .status-box {
                        h6 {
                            font-size: 13px;
                            font-weight: 400;
                        }
                    }
                }
                .active {
                    background-color: #0091d6;
                    h6 {
                        color: #fff;
                        margin: 0;
                    }
                }
            }
        }
    }
    .search-box {
        display: flex;
        align-items: center;;
        background-color: #fff;
        border: #ddd solid 2px;
        border-radius: 10px;
        padding: 0px 10px;
        input {
            background-color: #fff;
            padding: 10px 10px;
            width: 100%;
            border: none;
            border-radius: 5px;
            font-size: 14px;
            font-weight: 300;
            color: #000;
            &:focus {
                outline: none;
            }
        }
        #select-style-addresss {
            width: 150px;
            border-style: none !important;
        }
        .notyping {
            display: flex;
            align-items: center;
            .searchimg {
                width: 20px;
                height: 20px;
                cursor: pointer;
            }
        }
        .typing {
            display: flex;
            align-items: center;
            .removeimg {
                width: 17px;
                height: 17px;
                cursor: pointer;
            }
            .divider-line {
                width: 1px;
                height: 20px;
                background-color: #000;
                margin: 0 15px;
            }
            .searchimg {
                width: 20px;
                height: 20px;
                cursor: pointer;
            }
        }
        
    }
    .recently-view {
        padding: 6px 10px;
        display: flex;
        border-radius: 10px;
        cursor: pointer;
        justify-content: center;
        background-color: #fff;
        border: #ddd solid 2px;
        img {
            width: 18px;
            height: 18px;
            margin-right: 5px;
        }
    }
}



.container-item-invoice {
    margin: 15px 3px;
    padding: 20px;
    background-color: #fff;
    box-shadow: #ddd 0px 0px 10px;
    border-radius: 10px;
    .top-div {
        background-color: #fff;
        box-shadow: #ddd 0px 2px 0px;
        border-radius: 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 10px;
        .left {
            padding-bottom: 5px;
            display: flex;
            flex-wrap: wrap;
            // align-items: center;
            p {
                font-size: 14px;
                color: #000;
                font-weight: 400;
                padding-bottom: 5px;
            }
            h5 {
                font-size: 15px;
                color: #000;
                font-weight: bold;
                padding-bottom: 5px;
            }
            img {
                width: 18px;
                height: 18px;
                margin-right: 5px;
            }
            .line-right {
                border-right: #ddd 1px solid;
                margin: 2px 10px;
            }
        }
        .right {
            display: flex;
            align-items: center;
            padding-bottom: 5px;
            .status {
                padding: 3px 15px;
                border-radius: 12px;
                h6 {
                    font-size: 12px;
                    color: #fff;
                    font-weight: 400;
                }
            }
            img {
                cursor: pointer;
                width: 20px;
                height: 20px;
                margin-left: 5px;
                margin-right: 5px;
            }
        }
    }
    .center-div {
        padding: 2px 0;
        align-items: center;
        .left-box {
            margin-bottom: 1rem;
            .store {
                display: flex;
                align-items: center;
                img {
                    width: 50px;
                    height: 50px;
                    padding: 4px;
                    background-color: #ddd;
                    border-radius: 50%;
                }
                .name {
                    padding-left: 10px;
                    p {
                        font-size: 12px;
                        font-weight: 500;
                        letter-spacing: 0.5px;
                        color: #364153;
                    }
                    h2 {
                        color: #364153;
                        font-size: 17px;
                        font-weight: 500;
                        letter-spacing: 0.5px;
                        text-transform: capitalize;
                    }
                    .chat {
                        margin-top: 5px;
                        display: flex;
                        align-items: center;
                        .btn-chat {
                            cursor: pointer;
                            font-size: 12px;
                            margin-right: 7px;
                            justify-content: flex-end;
                            border: #1e88b9 2px solid;
                            padding: 2px 10px;
                            border-radius: 10px;
                            text-align: center;
                            color: #1e88b9;
                            -moz-transition: all .2s ease-in;
                            -o-transition: all .2s ease-in;
                            -webkit-transition: all .2s ease-in;
                            transition: all .2s ease-in;
                        }
                        .btn-chat:hover {
                            background-color: #1377a5;
                            border: #0d6d99 2px solid;
                            p {
                                color: #fff;
                            }
                        }
                        p {
                            font-size: 12px;
                            font-weight: 500;
                            letter-spacing: 0.5px;
                            color: #1e88b9;
                        }
                    }
                }
            }
        }
        .total-prices {
            // border-left: #ddd solid 1px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin: 3px 0;
            padding: 15px;
            p {
                font-size: 14px;
                color: #000;
                font-weight: 300;
                padding: 0 10px;
            }
            h3 {
                font-size: 17px;
                color: #000;
                font-weight: bold;
                margin: 0;
            }
        }
    }
    .bottom-div {
        display: flex;
        justify-content: space-between;   
        flex-wrap: wrap;     
        align-items: center;
        row-gap: 5px;
        .left-div {
            p {
                font-size: 14px;
                color: #000;
                font-weight: 500;
                text-decoration: underline;
            }
        }
        .right-div {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            row-gap: 5px;
            .btn-confirm {
                margin-right: 5px;
                button {
                    cursor: pointer;
                    background-color: #fff;
                    border: #0091d6 solid 1px;
                    box-shadow: #ddd 0px 0px 10px;
                    border-radius: 5px;
                    padding: 5px 10px;
                    transition: all 0.3s ease;
                    width: 100%;
                    text-align: center;
                    color: #0091d6;
                    font-size: 13px;
                    font-weight: 500;
                }
                :disabled {
                    background-color: #ddd;
                    border: #ddd solid 1px;
                    color: rgb(168, 165, 165);
                    cursor: not-allowed;
                    font-weight: 400;
                    border: none;
                }
            }
            .btn-confirm:hover {
                margin: 0 5px;
                button {
                    cursor: pointer;
                    background-color: #0091d6;
                    border: #fff solid 1px;
                    box-shadow: #ddd 0px 0px 10px;
                    border-radius: 5px;
                    padding: 5px 10px;
                    width: 100%;
                    text-align: center;
                    color: #fff;
                    font-size: 13px;
                    font-weight: 500;
                }
                :disabled {
                    background-color: #ddd;
                    color: rgb(168, 165, 165);
                    cursor: not-allowed;
                    outline: none;
                    border: none;
                    font-weight: 400;
                }
            }
            
            .view-btn {
                display: flex;
                cursor: pointer;
                align-items: center;
                padding: 0 10px;
                img {
                    width: 20px;
                    height: 20px;
                    margin-left: 5px;
                }
                p {
                    font-size: 15px;
                    color: #0091d6;
                    font-weight: bold;
                }
            }
        }
    }
}



.item-card-invoice {
    margin-top: 15px;
    max-height: calc(100vh - 250px);
    overflow-x: auto;
}

.selected-item {
    box-shadow: #2ecc71 0px 0px 5px !important;
    background-color: #c8e6c9 !important;
    .top-div {
        background-color: #c8e6c9 !important;
        box-shadow: #fff 0px 2px 0px !important;
    }
    .card-product-items {
        .item-product {
            background-color: #fff !important;
        }
    }
}

.container-item-order-inv {
    margin: 15px 5px;
    padding: 20px;
    background-color: #fff;
    box-shadow: #ddd 0px 0px 10px;
    border-radius: 10px;
    // height: 150px;
    // width: 100%;
    .top-div {
        background-color: #fff;
        box-shadow: #ddd 0px 2px 0px;
        border-radius: 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 10px;
        .left {
            cursor: pointer;
            padding-bottom: 5px;
            display: flex;
            flex-wrap: wrap;
            h6 {
                font-size: 15px;
                color: #000;
                font-weight: bold;
            }
            .radio-input {
                margin-right: 5px;
                input {
                    cursor: pointer;
                    width: 15px;
                    height: 15px;
                }
                input[type='radio'] {
                    accent-color: green;
                }
            }
        }
        @include breakpoints.media('<=tablet') {
            .left {
                h6 {
                    font-size: 13px;
                }
            }
        }
        .right {
            display: flex;
            align-items: center;
            padding-bottom: 5px;
            .status {
                padding: 3px 10px;
                border-radius: 5px;
                h6 {
                    font-size: 14px;
                    color: #fff;
                    font-weight: 400;
                }
            }
            img {
                cursor: pointer;
                width: 20px;
                height: 20px;
                margin-left: 5px;
                margin-right: 5px;
            }
        }
    }
    .center-div {
        padding: 2px 0;
        align-items: center;
        .left-box {
            .store {
                display: flex;
                align-items: center;
                img {
                    width: 50px;
                    height: 50px;
                    padding: 4px;
                    background-color: #ddd;
                    border-radius: 50%;
                }
                .name {
                    padding-left: 10px;
                    h2 {
                        color: #364153;
                        font-size: 17px;
                        font-weight: 500;
                        letter-spacing: 0.5px;
                        text-transform: capitalize;
                    }
                    .chat {
                        display: flex;
                        align-items: center;
                        .btn-chat {
                            cursor: pointer;
                            font-size: 12px;
                            margin-right: 7px;
                            justify-content: flex-end;
                            border: #0091d6 2px solid;
                            padding: 2px 10px;
                            border-radius: 10px;
                            text-align: center;
                            color: #0091d6;
                            -moz-transition: all .2s ease-in;
                            -o-transition: all .2s ease-in;
                            -webkit-transition: all .2s ease-in;
                            transition: all .2s ease-in;
                        }
                        .btn-chat:hover {
                            background-color: #1e88b9;
                            border: #1e88b9 2px solid;
                            p {
                                color: #fff;
                            }
                        }
                        p {
                            font-size: 12px;
                            font-weight: 500;
                            letter-spacing: 0.5px;
                            color: #0091d6;
                        }
                    }
                }
            }
        }
        .content-right-inv {
            padding-top: 1rem;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            .content {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                p {
                    font-size: 14px;
                    color: #000;
                    font-weight: 300;
                    padding: 0 10px;
                }
                h2 {
                    font-size: 18px;
                    color: #000;
                    font-weight: bold;
                    margin: 0;
                }
            }
        }
    }
    .bottom-div {
        margin-top: 5px;
        display: flex;
        justify-content: space-between;        
        align-items: center;
        flex-wrap: wrap;
        row-gap: 5px;
        .left-div {
            p {
                font-size: 14px;
                color: #000;
                font-weight: 500;
                text-decoration: underline;
            }
        }
        .right-div {
            display: flex;
            align-items: center;
            
            .view-btn {
                display: flex;
                cursor: pointer;
                align-items: center;
                img {
                    width: 20px;
                    height: 20px;
                    margin-left: 5px;
                }
                p {
                    font-size: 15px;
                    color: #0091d6;
                    font-weight: bold;
                }
            }
            .line-center {
                border-left: #ddd solid 1px;
                height: 20px; 
                margin: 0px 10px;
            }
            .print-btn {
                display: flex;
                cursor: pointer;
                align-items: center;
                p {
                    font-size: 14px;
                    color: #0091d6;
                    font-weight: 400;
                }
            }
        }
    }
}

.invoice-container {
    // margin: 5px 0;
    margin-bottom: 60px;
    margin-bottom: 100px;
    padding: 25px;
    background-color: #fff;
    box-shadow: #ddd 0px 0px 6px;
    border-radius: 6px;
    width: 100%;
    table {
        border: 1px solid #ccc;
        border-collapse: collapse;
        margin: 0;
        padding: 0;
        width: 100%;
        table-layout: fixed;
      }
      
      table caption {
        font-size: 1.5em;
        margin: .5em 0 .75em;
      }
      
      table tr {
        background-color: #f8f8f8;
        border: 1px solid #ddd;
        padding: .35em;
      }
      
      table th,
      table td {
        padding: .625em;
        text-align: center;
      }
      
      table th {
        font-size: .85em;
        letter-spacing: .1em;
        text-transform: uppercase;
      }
      
      @media screen and (max-width: 600px) {
        table {
          border: 0;
        }
      
      
        table thead {
          border: none;
          clip: rect(0 0 0 0);
          height: 1px;
          margin: -1px;
          overflow: hidden;
          padding: 0;
          position: absolute;
          width: 1px;
          background-color: red;
          text-transform: capitalize;
        }
        
        table tr {
          border-bottom: 3px solid #ddd;
          display: block;
          margin-bottom: .625em;
        }
        
        table td {
          border-bottom: 1px solid #ddd;
          display: block;
          font-size: .8em;
          text-align: right;
        }
        
        table td::before {
          /*
          * aria-label has no advantage, it won't be read inside a table
          content: attr(aria-label);
          */
          content: attr(data-label);
          float: left;
          font-weight: bold;
          text-transform: uppercase;
        }
        
        table td:last-child {
          border-bottom: 0;
        }
      }
      
      
            
    .logo-company {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: center;
        .logo {
            width: 60%;
            img {
                border-radius: 5px;
                width: 100px;
                height: 100px;
            }
        }
        .company {
            width: 40%;
            h2 {
                font-size: 15px;
                color: #000;
                font-weight: bold;
                margin: 0;
            }
            p {
                font-size: 12px;
                color: #000;
                font-weight: 300;
                margin: 0;
                text-transform: capitalize;
            }
        }
    }
    .invoice-info {
        padding-top: 10px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        // align-items: center;
        .bill-to {
            padding: 10px 0;
            width: 60%;
            h3 {
                font-size: 17px;
                color: #000;
                font-weight: bold;
                margin: 0;
            }
            h4 {
                font-size: 15px;
                color: #000;
                font-weight: 500;
                margin: 0;
            }
            p {
                font-size: 12px;
                color: #000;
                font-weight: 300;
                margin: 0;
                text-transform: capitalize;
            }
        }
        .inv-number {
            padding: 10px 0;
            width: 40%;
            h6 {
                font-size: 22px;
                color: #000;
                font-weight: 500;
                letter-spacing: 2px;
            }
            .list {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                // justify-content: space-between;
                h5 {
                    font-size: 13px;
                    color: #000;
                    font-weight: 300;
                    margin: 0;
                    padding-right: 10px;
                }
                p {
                    font-size: 13px;
                    color: green;
                    font-weight: 500;;
                }
            }
            .inv-status {
                display: flex;
                align-items: center;
                h6 {
                    text-transform: uppercase;
                }
                .status {
                    margin-left: 10px;
                    border-radius: 5px;
                    padding: 2px 5px;
                    background-color: #43a047;
                    color: #fff;
                    h5 {
                        font-size: 13px;
                        color: #fff;
                        font-weight: 500;
                        margin: 0;
                        letter-spacing: 2px;
                    }
                    // border: #06550a 1px solid;
                }
            }
        }
    }

    .invoice-table {
        padding: 10px 0;
        min-height: 500px;
        overflow-x: auto;
        overflow-y: auto;
        // height: fit-content;
        // min-height: calc(100vh - 105px);
        // min-width: 400px;
        tr:nth-child(even){background-color: #f2f2f2}
        table {
            width: 100%;
            border-collapse: collapse;
            border-spacing: 0;
            tr {
                .total {
                    text-align: center;
                    min-width: 150px;
                    font-size: 17px;
                    font-weight: 400;
                }
                .totals {
                    text-align: center;
                }
                .item {
                    min-width: 150px;
                    font-size: 17px;
                    font-weight: 400;
                }
                .price {
                    min-width: 100px;
                    font-size: 17px;
                    font-weight: 400;
                }
                .tax {
                    min-width: 50px;
                    font-size: 17px;
                    font-weight: 400;
                }
                .qty {
                    min-width: 50px;
                    font-size: 17px;
                    font-weight: 400;
                }
                th {
                    background-color: #0091d6;
                    font-size: 15px;
                    color: #fff;
                    font-weight: 400;
                    padding: 10px;
                    text-align: left;
                }
                td {
                    font-size: 13px;
                    color: #000;
                    font-weight: 300;
                    padding: 10px;
                    text-align: left;
                }
            }
            .endline {
                background-color: #fff !important;
                .total-text {
                    font-weight: 400;
                    font-size: 15px;
                    margin: 0;
                    padding: 0;
                    text-align: center;
                }
                .total-value {
                    font-weight: 400;
                    text-align: center;
                    min-width: 150px;
                    font-size: 15px;
                    margin: 0;
                    padding: 2px 0;
                    text-align: center;
                }
            }
            .grand-text {
                // background-color: #0091d6;
                width: fit-content;
                font-weight: bold;
                font-size: 17px;
                margin: 0;
                padding: 0;
                text-align: center;
                color: #0091d6;
            }
            .grand-value {
                // background-color: #0091d6;
                font-weight: bold;
                text-align: center;
                min-width: 150px;
                font-size: 17px;
                margin: 0;
                padding: 2px 0;
                text-align: center;
                color: #0091d6;
            }
        }
    }
    .invoice-sum {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        padding: 10px 0;
        margin-top: 10px;
        // float: right;
        .item-sum {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
            h5 {
                font-size: 14px;
                color: #000;
                font-weight: 300;
            }
            h2 {
                font-size: 16px;
                color: #000;
                padding-left: 80px;
                font-weight: 500;
            }
        }
        .item-sum-total {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
            h5 {
                font-size: 14px;
                color: #000;
                font-weight: 500;
            }
            h2 {
                font-size: 16px;
                color: #000;
                padding-left: 80px;
                font-weight: 500;
            }
        }
        
    }
    @include breakpoints.media('<=phone') {
        // .invoice-table {
        //     min-width: 300px;
        //     overflow-x: auto;
        // }
        .logo-company {
            .logo {
               width: 100%;
            }
            .company {
               width: 100%;
            }
        }
        .invoice-info {
            .bill-to {
               width: 100%;
            }
            .inv-number {
               width: 100%;
            }
        }
        .invoice-sum {
            
            .item-sum {
                h5 {
                    font-size: 10px;
                    font-weight: 300;
                }
                h2 {
                    font-size: 12px;
                    padding-left: 80px;
                    font-weight: 500;
                }
            }
            .item-sum-total {
                h5 {
                    font-size: 10px;
                    color: #000;
                    font-weight: 500;
                }
                h2 {
                    font-size: 12px;
                    color: #000;
                    padding-left: 50px;
                    font-weight: 500;
                }
            }
            
        }
    }
    @include breakpoints.media('>phone', '<=tablet') {
        // .invoice-table {
        //     min-width: 400px;
        //     overflow-x: auto;
        // }
        .logo-company {
            .logo {
               width: 100%;
            }
            .company {
               width: 100%;
            }
        }
        .invoice-info {
            .bill-to {
               width: 100%;
            }
            .inv-number {
               width: 100%;
            }
        }
    }
    @include breakpoints.media('>tablet', '<=desktop') {
        .invoice-table {
            width: 100%;
        }
    }
    @include breakpoints.media('>desktop') {
        .invoice-table {
            width: 100%;
        }
    }

    .document-referance {
        margin: 15px 0;
        h6 {
            font-size: 17px;
            color: #000;
            font-weight: bold;
        }
        p {
            font-size: 12px;
            color: #000;
            font-weight: 300;
        }
    }

    .line-bottom {
        border-bottom: 2px solid #0091d6;
        margin: 5px 0;
        padding: 0;
    }
    .invoice-footer {
        margin: 5px 0;
        text-align: center;
        p{
            font-size: 13px;
            color: #0091d6;
            font-weight: 300;
            margin: 0;
        }
    }
}



.payment-container {
    margin-top: 10px;
    margin-bottom: 15px;
    // background-color: #fff;
    border: #ddd solid 1px;
    // box-shadow: #ddd 0px 0px 10px;
    border-radius: 5px;
    padding: 15px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    .method {
        .name {
            margin-bottom: 5px;
            border: #06550a solid 1px;
            width: fit-content;
            border-radius: 5px;
            padding: 2px 7px;
            h5 {
                font-size: 15px;
                color: #06550a;
                font-weight: 500;
            }
        }
        h6 {
            font-size: 18px;
            color: #000;
            font-weight: bold;
            padding-bottom: 5px;
        }
       
        .select-method {
            cursor: pointer;
            display: flex;
            align-items: center;
            h5 {
                font-size: 15px;
                color: #06550a;
                font-weight: 500;
            }
            img {
                width: 20px;
                height: 20px;
                margin-left: 5px;
            }
            .selected {
                display: flex;
                align-items: center;
                img {
                    width: 55px;
                    height: 30px;
                    margin-right: 10px;
                }
            }
        }
        .error {
            h5 {
                color: red !important
            }
        }
        .detail {
            display: flex;
            align-items: center;
            padding: 3px 0;
            h6 {
                font-size: 15px;
                color: #000;
                font-weight: bold;
                margin: 0;
                padding: 0;
            }
            p {
                font-size: 15px;
                color: #000;
                font-weight: 300;
                margin: 0;
                padding: 0 10px 0 0;
            }
            a {
                cursor: pointer;
                font-size: 15px;
                color: #06550a;
                font-weight: bold;
                margin: 0;
                padding: 0;
            }
        }
    }
    .btn-right {
        display: flex;
        align-items: center;
        .download-btn {
            padding: 0 10px;
            p {
                cursor: pointer;
                font-size: 15px;
                color: #43a047;
                font-weight: bold;
                margin: 0;
                padding: 0;
            }
        }
    }
}