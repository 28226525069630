
@use '../breakpoints/breakpoints.scss';


.container-search {
    margin-top: 10px;
    background-color: #fff;
    border: #ddd solid 1 px;
    box-shadow: #ddd 0px 0px 15px;
    border-radius: 10px;
    // height: 150px;
    // position: fixed;
    padding: 15px;
    .status {
        display: flex; 
        flex-direction: row;
        align-items: center;
        padding-top: 15px;
        h5 {
            font-size: 15px;
            font-weight: 500;
            color: #000;
            text-transform: capitalize;
        }
        .list-status {
            padding-left: 10px;
            display: flex;
            flex-wrap: wrap;
            .container-status {
                position:relative;
                padding-top:10px;
                display:inline-block;
                margin: 5px 10px;
                .status-box {
                    // background-color: #fff;
                    border: #ddd solid 1px;
                    box-shadow: #ddd 0px 0px 10px;
                    padding: 5px 15px;
                    border-radius: 10px;
                    cursor: pointer;
                    h6 {
                        font-size: 16px;
                        font-weight: 400;
                        color: #092c4c;
                        text-transform: capitalize;
                        margin: 0;
                    }
                }
                @include breakpoints.media('>phone', '<=tablet') {
                    .status-box {
                        h6 {
                            font-size: 13px;
                            font-weight: 400;
                        }
                    }
                }
                .active {
                    background-color: #0091d6;
                    h6 {
                        color: #fff;
                        margin: 0;
                    }
                }
            }
            .notify-badge{
                position: absolute;
                right:-18px;
                top:-5px;
                background:#c70000;
                text-align: center;
                border-radius: 50%;
                color:white;
                padding: 3px 4px;
                min-width: 25px;
                height: 24px;
                font-size:12px;
                z-index: 1000;
                font-family: 'Roboto', sans-serif;
            }
        }
    }
    .search-box {
        display: flex;
        align-items: center;;
        background-color: #fff;
        border: #ddd solid 2px;
        border-radius: 10px;
        padding: 0px 10px;
        input {
            background-color: #fff;
            padding: 16px 10px;
            width: 100%;
            border: none;
            border-radius: 5px;
            font-size: 16px;
            font-weight: 300;
            color: #000;
            &:focus {
                outline: none;
            }
        }
        #select-style-addresss {
            width: 150px;
            border-style: none !important;
        }
        .notyping {
            display: flex;
            align-items: center;
            .searchimg {
                width: 20px;
                height: 20px;
                cursor: pointer;
            }
        }
        .typing {
            display: flex;
            align-items: center;
            .removeimg {
                width: 17px;
                height: 17px;
                cursor: pointer;
            }
            .divider-line {
                width: 1px;
                height: 20px;
                background-color: #000;
                margin: 0 15px;
            }
            .searchimg {
                width: 20px;
                height: 20px;
                cursor: pointer;
            }
        }
        
    }
    .recently-view {
        padding: 13px 10px;
        display: flex;
        border-radius: 10px;
        cursor: pointer;
        justify-content: center;
        background-color: #fff;
        border: #ddd solid 2px;
        img {
            width: 18px;
            height: 18px;
            margin-right: 5px;
        }
    }
}

.item-card-fulfillment {
    max-height: calc(100vh - 355px);
    overflow-x: auto;
}

.disable-item {
    background-color: #ccc  !important;
    box-shadow: #ccc 0px 0px 10px;
    .top-div {
        background-color: #ccc !important;
        box-shadow: #fff 0px 2px 0px !important;
    }
    .right-div-disabled {
        display: flex;
        align-items: center;
        .view-btn-outlined {
            cursor: pointer;
            padding: 5px 10px;
            border-radius: 6px;
            p {
                font-size: 14px;
                color: #0091d6;
                font-weight: 500;
            }
        }
        .view-btn {
            background-color: #f9ca00; 
            padding: 5px 15px;
            border-radius: 15px;
            p {
                font-size: 14px;
                color: #1b4559;
                font-weight: bold;
            }
        }
        .view-btn-confirm {
            background-color: #0091d6; 
            padding: 5px 15px;
            border-radius: 15px;
            cursor: pointer;
            p {
                font-size: 13px;
                color: #fff;
                font-weight: 500;
            }
        }
    }
}

.container-item-purchase {
    margin: 15px 3px;
    padding: 20px;
    background-color: #fff;
    box-shadow: #ddd 0px 0px 10px;
    border-radius: 10px;
    // height: 150px;
    // width: 100%;
    .top-div {
        background-color: #fff;
        box-shadow: #ddd 0px 2px 0px;
        border-radius: 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 10px;
        .left {
            padding-bottom: 5px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            p {
                font-size: 14px;
                color: #000;
                font-weight: 400;
                padding-bottom: 5px;
            }
            h6 {
                font-size: 15px;
                color: #000;
                font-weight: bold;
            }
            .history {
                img {
                    width: 25px;
                    height: 30px;
                    padding-top: 5px;
                    cursor: pointer;
                }
            }
            .line-right {
                border-right: #ddd 1px solid;
                margin: 0 10px;
            }
            .statuses {
                display: flex;
                align-items: center;
                border-left: #0091d6 1px solid;
                padding: 1px 10px;
                margin-left: 0.7rem;
                // border-radius: 5px;
                h5 {
                    font-size: 14px;
                    color: #1f3026;
                    font-weight: 500;
                }
            }
            @include breakpoints.media('<=tablet') {
                h6 {
                    font-size: 13px;
                }
                .statuses {
                    border-left: none;
                    margin-left: 0;
                    padding-left: 0;
                }
            }
           
        }
        .right {
            display: flex;
            align-items: center;
            padding-bottom: 5px;
            .status {
                padding: 3px 10px;
                border-radius: 5px;
                h6 {
                    font-size: 14px;
                    color: #fff;
                    font-weight: 400;
                }
            }
            img {
                cursor: pointer;
                width: 20px;
                height: 20px;
                margin-left: 5px;
                margin-right: 5px;
            }
        }
        @include breakpoints.media('<=tablet') {
            .right {
                display: flex;
                justify-self: flex-end;
            }
        }
    }
    .center-div {
        padding: 2px 0;
        align-items: center;
        .left-box {
            .store {
                display: flex;
                align-items: center;
                img {
                    width: 50px;
                    height: 50px;
                    padding: 4px;
                    background-color: #ddd;
                    border-radius: 50%;
                }
                .name {
                    padding-left: 10px;
                    p {
                        font-size: 12px;
                        font-weight: 500;
                        letter-spacing: 0.5px;
                        color: #364153;
                    }
                    h2 {
                        color: #364153;
                        font-size: 17px;
                        font-weight: 500;
                        letter-spacing: 0.5px;
                        text-transform: capitalize;
                    }
                    .chat {
                        display: flex;
                        align-items: center;
                        .btn-chat {
                            cursor: pointer;
                            font-size: 12px;
                            margin-right: 7px;
                            justify-content: flex-end;
                            border: #0091d6 2px solid;
                            padding: 2px 10px;
                            border-radius: 10px;
                            text-align: center;
                            color: #0091d6;
                            -moz-transition: all .2s ease-in;
                            -o-transition: all .2s ease-in;
                            -webkit-transition: all .2s ease-in;
                            transition: all .2s ease-in;
                        }
                        .btn-chat:hover {
                            background-color: #1e88b9;
                            border: #1e88b9 2px solid;
                            p {
                                color: #fff;
                            }
                        }
                        p {
                            font-size: 12px;
                            font-weight: 500;
                            letter-spacing: 0.5px;
                            color: #0091d6;
                        }
                    }
                }
            }
            .address {
                padding: 10px 0;
                p {
                    font-size: 14px;
                    font-weight: 400;
                    color: #364153;
                    text-transform: capitalize;
                }
                h2 {
                    color: #364153;
                    font-size: 17px;
                    padding-left: 0px !important;
                    font-weight: 500;
                }
            }
           
            .vendor-list {
                padding: 7px 0;
                display: flex;
                flex-wrap: wrap;
                .box-vendor {
                    border: #ddd 1px solid;
                    box-shadow: #ddd 0px 0px 10px;
                    padding: 5px 10px;
                    margin: 5px;
                    border-radius: 5px;
                    h3 {
                        text-transform: capitalize;
                        font-size: 15px;
                        color: #000;
                        font-weight: 400;
                    }
                }
                
            }
        }
        .total-prices {
            // border-left: #ddd solid 1px;
            display: flex;
            flex-direction: row;
            margin: 3px 0;
            padding: 15px;
            p {
                font-size: 14px;
                color: #000;
                font-weight: 300;
                padding-bottom: 5px;
            }
            h3 {
                font-size: 17px;
                color: #000;
                font-weight: bold;
                margin: 0;
            }
        }
        .content-right {
            padding-top: 1rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            // justify-content: flex-end;
            .content {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                p {
                    font-size: 14px;
                    color: #000;
                    font-weight: 300;
                }
                h3 {
                    font-size: 16px;
                    color: #000;
                    font-weight: 500;
                    margin: 0;
                }
                h2 {
                    font-size: 18px;
                    color: #000;
                    font-weight: bold;
                    margin: 0;
                }
            }
            .linedivder {
                border-bottom: #ddd 1px solid;
                margin: 10px 0;
            }
        }
        .content-right-inv {
            padding-top: 1rem;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            .content {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                p {
                    font-size: 14px;
                    color: #000;
                    font-weight: 300;
                    padding: 0 10px;
                }
                h2 {
                    font-size: 18px;
                    color: #000;
                    font-weight: bold;
                    margin: 0;
                }
            }
        }
    }
    @include breakpoints.media('<=tablet') {
        .bottom-div  {
            display: flex;
            flex-direction: column;
            .left-divv {
                display: flex;
                justify-content: space-between;
            }
            .right-div {
                margin-top: 10px;
                display: flex;
                .view-btn {
                    padding-top: 1rem;
                }
            }
        }
    }
    .bottom-div {
        margin-top: 5px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;        
        align-items: center;
        padding-left: 0px !important;
        .left-divv {
            display: flex;
            align-items: center;
            p {
                padding-left: 0px !important;
                font-size: 14px;
                color: #000;
                font-weight: 500;
                text-decoration: underline;
            }
            .view-note {
                padding-left: 10px;
                h6 {
                    cursor: pointer;
                    font-size: 14px;
                    color: #0091d6;
                    font-weight: 500
                }
            }
        }
        .right-div {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            .btn-pr-submit {
                margin: 0 10px;
                button {
                    cursor: pointer;
                    background-color: #fff;
                    border: #0091d6 solid 1px;
                    box-shadow: #ddd 0px 0px 10px;
                    border-radius: 8px;
                    padding: 5px 15px;
                    transition: all 0.3s ease;
                    width: 100%;
                    text-align: center;
                    color: #0091d6;
                    font-size: 14px;
                    font-weight: 500;
                }
                :disabled {
                    background-color: #ddd;
                    border: #ddd solid 1px;
                    color: rgb(168, 165, 165);
                    cursor: not-allowed;
                }
            }
            .btn-pr-submit:hover {
                button {
                    cursor: pointer;
                    background-color: #0091d6;
                    border: #fff solid 1px;
                    box-shadow: #ddd 0px 0px 10px;
                    border-radius: 8px;
                    padding: 5px 15px;
                    width: 100%;
                    text-align: center;
                    color: #fff;
                    font-size: 14px;
                    font-weight: 500;
                }
                :disabled {
                    background-color: #ddd;
                    color: rgb(168, 165, 165);
                    cursor: not-allowed;
                }
            }
            .btn-pr-reject {
                button {
                    cursor: pointer;
                    background-color: #fff;
                    border: #e8505b solid 1px;
                    box-shadow: #ddd 0px 0px 10px;
                    border-radius: 5px;
                    padding: 8px 20px;
                    transition: all 0.3s ease;
                    width: 100%;
                    text-align: center;
                    color: #e8505b;
                    font-size: 14px;
                    font-weight: 500;
                }
                :disabled {
                    background-color: #ddd;
                    border: #ddd solid 1px;
                    color: rgb(168, 165, 165);
                    cursor: not-allowed;
                }
            }
            .btn-pr-reject:hover {
                button {
                    cursor: pointer;
                    background-color: #e8505b;
                    border: #fff solid 1px;
                    box-shadow: #ddd 0px 0px 10px;
                    border-radius: 5px;
                    padding: 8px 20px;
                    width: 100%;
                    text-align: center;
                    color: #fff;
                    font-size: 14px;
                    font-weight: 500;
                }
                :disabled {
                    background-color: #ddd;
                    color: rgb(168, 165, 165);
                    cursor: not-allowed;
                }
            }
            .view-btn {
                display: flex;
                cursor: pointer;
                align-items: center;
                img {
                    width: 20px;
                    height: 20px;
                    margin-left: 5px;
                }
                p {
                    font-size: 15px;
                    color: #0091d6;
                    font-weight: bold;
                }
            }
            .line-center {
                border-left: #ddd solid 1px;
                height: 20px;
                margin: 0px 10px;
            }
            .print-btn {
                display: flex;
                cursor: pointer;
                align-items: center;
                p {
                    font-size: 14px;
                    color: #0091d6;
                    font-weight: 400;
                }
            }
            .btn-inv-submit {
                margin: 0;
                button {
                    cursor: pointer;
                    background-color: #0091d6;
                    border: #0091d6 solid 1px;
                    // box-shadow: #ddd 0px 0px 10px;
                    border-radius: 8px;
                    padding: 5px 10px;                  transition: all 0.3s ease;
                    width: 100%;
                    text-align: center;
                    color: #fff;
                    font-size: 14px;
                    font-weight: 500;
                }
                :disabled {
                    background-color: #ddd;
                    border: #ddd solid 1px;
                    color: rgb(168, 165, 165);
                    cursor: not-allowed;
                }
            }
            .btn-inv-submit:hover {
                button {
                    cursor: pointer;
                    background-color: #0d6f9c;
                    border: #0d6f9c solid 1px;
                    // box-shadow: #ddd 0px 0px 10px;
                    border-radius: 8px;
                    padding: 5px 10px;
                    width: 100%;
                    text-align: center;
                    color: #fff;
                    font-size: 14px;
                    font-weight: 500;
                }
                :disabled {
                    background-color: #ddd;
                    color: rgb(168, 165, 165);
                    cursor: not-allowed;
                }
            }
        }
        @include breakpoints.media('<=tablet') {
            
        }
    }
}

.card-product-items {
    margin: 15px 0;
    // box-shadow: #ddd 0px 0px 15px;
    border: 1px solid #0091d6;
    border-radius: 5px;
    padding: 15px;
    .container-btn-item {
        display: flex;
        padding-bottom: 5px;
        flex-wrap: wrap;
        row-gap: 5px;
        margin: 0 5px;
        .print-btn {
            margin: 0 15px;
            display: flex;
            cursor: pointer;
            align-items: center;
            p {
                font-size: 14px;
                color: #0091d6;
                font-weight: 400;
            }
        }
        .btn-pr-submit {
            button {
                cursor: pointer;
                background-color: #fff;
                border: #0091d6 solid 1px;
                box-shadow: #ddd 0px 0px 10px;
                border-radius: 10px;
                padding: 5px 15px;
                transition: all 0.3s ease;
                width: 100%;
                text-align: center;
                color: #0091d6;
                font-size: 14px;
                font-weight: 500;
            }
            :disabled {
                background-color: #ddd;
                border: #ddd solid 1px;
                color: rgb(168, 165, 165);
                cursor: not-allowed;
            }
        }
        .btn-pr-submit:hover {
            button {
                cursor: pointer;
                background-color: #0091d6;
                border: #fff solid 1px;
                box-shadow: #ddd 0px 0px 10px;
                border-radius: 10px;
                padding: 5px 15px;
                width: 100%;
                text-align: center;
                color: #fff;
                font-size: 14px;
                font-weight: 500;
            }
            :disabled {
                background-color: #ddd;
                color: rgb(168, 165, 165);
                cursor: not-allowed;
            }
        }
        .btn-pr-reject {
            button {
                cursor: pointer;
                background-color: #fff;
                border: #e8505b solid 1px;
                box-shadow: #ddd 0px 0px 10px;
                border-radius: 10px;
                padding: 5px 15px;
                transition: all 0.3s ease;
                width: 100%;
                text-align: center;
                color: #e8505b;
                font-size: 14px;
                font-weight: 500;
            }
            :disabled {
                background-color: #ddd;
                border: #ddd solid 1px;
                color: rgb(168, 165, 165);
                cursor: not-allowed;
            }
        }
        .btn-pr-reject:hover {
            button {
                cursor: pointer;
                background-color: #e8505b;
                border: #fff solid 1px;
                box-shadow: #ddd 0px 0px 10px;
                border-radius: 10px;
                padding: 5px 15px;
                width: 100%;
                text-align: center;
                color: #fff;
                font-size: 14px;
                font-weight: 500;
            }
            :disabled {
                background-color: #ddd;
                color: rgb(168, 165, 165);
                cursor: not-allowed;
            }
        }
        .btn-item-submit {
            margin: 0 5px;
            button {
                cursor: pointer;
                background-color: #0091d6;
                border: #fff solid 1px;
                box-shadow: #ddd 0px 0px 10px;
                border-radius: 10px;
                padding: 5px 15px;
                width: 100%;
                text-align: center;
                transition: all 0.3s ease;
                color: #fff;
                font-size: 14px;
                font-weight: 500;
            }
            :disabled {
                background-color: #ddd;
                color: rgb(168, 165, 165);
                cursor: not-allowed;
            }
        }
        .btn-item-submit:hover {
            margin: 0 5px;
            button {
                cursor: pointer;
                background-color: #1b7aa7;
                border: #fff solid 1px;
                box-shadow: #ddd 0px 0px 10px;
                border-radius: 10px;
                padding: 5px 15px;
                width: 100%;
                text-align: center;
                color: #fff;
                font-size: 14px;
                font-weight: 500;
            }
            :disabled {
                background-color: #ddd;
                color: rgb(168, 165, 165);
                cursor: not-allowed;
            }
        }
    }

    .container-btn-submit {
        margin: 0 5px;
        display: flex;
        justify-content: flex-end;
        padding-bottom: 5px;
        .btn-item-submit {
            button {
                cursor: pointer;
                background-color: #0091d6;
                border: #fff solid 1px;
                box-shadow: #ddd 0px 0px 10px;
                border-radius: 10px;
                padding: 7px 17px;
                width: 100%;
                text-align: center;
                transition: all 0.3s ease;
                color: #fff;
                font-size: 14px;
                font-weight: 500;
            }
            :disabled {
                background-color: #ddd;
                color: rgb(168, 165, 165);
                cursor: not-allowed;
            }
        }
        .btn-item-submit:hover {
            button {
                cursor: pointer;
                background-color: #0f80b4;
                border: #fff solid 1px;
                box-shadow: #ddd 0px 0px 10px;
                border-radius: 10px;
                padding: 7px 17px;
                width: 100%;
                text-align: center;
                color: #fff;
                font-size: 14px;
                font-weight: 500;
            }
            :disabled {
                background-color: #ddd;
                color: rgb(168, 165, 165);
                cursor: not-allowed;
            }
        }
    }

    .wrapper-item {
        max-height: 300px;
        overflow-y: auto;
    }
    .active-update {
        box-shadow: #0091d6 0px 0px 5px !important;
    }
    .approval {
        box-shadow: red 0px 0px 3px !important;
    }
    .approved {
        box-shadow: green 0px 0px 3px !important;
    }
    .item-product {
        display: flex;
        box-shadow: #ddd 0px 0px 5px;
        margin: 10px 5px;
        border-radius: 10px;
        padding: 10px;
        img {
            width: 50px;
            height: 50px;
            border-radius: 10px;
            background-color: #fff;
        }
        .right-product {
            width: 100%;
            margin: 0 15px;
            .title-product {
                h2 {
                    font-size: 15px;
                    font-weight: 400;
                    color: #364153;
                }
            }
            .inbound {
                .total-prices {
                    background-color: #ddd;
                    padding: 4px;
                    border-radius: 5px;
                    h2 {
                        font-size: 14px !important;
                        font-weight: 400 !important;
                        // color: #000 !important;
                        text-decoration: line-through;
                    }
                } 
                .total-tax {
                    margin-top: 0!important;
                    background-color: #ddd;
                    padding: 4px;
                    border-radius: 5px;
                    h2 {
                        font-size: 13px !important;
                        font-weight: 400 !important;
                        // color: #000 !important;
                        text-decoration: line-through;
                    }
                }
                .total-qty {
                    background-color: #ddd;
                    padding: 4px;
                    border-radius: 5px;
                    h2 {
                        font-size: 12px !important;
                        font-weight: 500;
                        color: #000 !important;
                        text-decoration: line-through;
                    }
                }
                .discount-amount {
                    background-color: #eaa24b;
                    border-radius: 5px;
                    padding: 2px 5px;
                    h3 {
                        color: #fff;
                        font-size: 11px;
                        font-weight: 300;
                    } 
                }
                .title-price {
                    background-color: #ddd;
                    padding: 4px;
                    border-radius: 5px;
                    h1 {
                        color: #364153;
                        font-size: 14px !important;
                        font-weight: 400 !important;
                        text-decoration: line-through;
                    }
                }
                .title-discount {
                    display: flex;
                    align-items: center;
                    margin-left: 10px;
                    margin-right: 5px;
                    background-color: #ddd;
                    padding: 4px;
                    border-radius: 5px;
                    h2 {
                        color: #364153;
                        font-size: 12px !important;
                        font-weight: 300;
                        padding-right: 5px;
                        text-decoration: line-through;
                    }
                }
            }
            .bottom-content {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                padding-top: 5px;
                .left-box {
                    .input-note {
                        input {
                            width: 150%;
                            padding: 0 5px;
                            height: 30px;
                            border-radius: 5px;
                            outline: none;
                            border: 1px solid #1b4559;
                        }
                    }

                    @include breakpoints.media('<=tablet') {
                        .input-note {
                            input {
                                width: 100%;
                            }
                            padding-bottom: 5px;
                        }    
                    }
                }
                @include breakpoints.media('<=tablet') {
                    .right-box {
                        justify-content: center;
                    }
                }
                .right-box {
                    display: flex;
                    justify-content: flex-end;
                    flex-wrap: wrap;
                    .total-tax {
                        border-radius: 5px;
                        height: fit-content;
                        margin-top: 3px;
                        h2 {
                            font-size: 13px;
                            font-weight: 300;
                            color: #1b4559;
                        }
                    }
                    .total-qty {
                        h2 {
                            font-size: 14px;
                            font-weight: 500;
                            color: #1b4559;
                        }
                    }
                    .total-prices {
                        border-radius: 5px;
                        height: fit-content;
                        h2 {
                            font-size: 16px;
                            font-weight: bold;
                            color: #1b4559;
                        }
                    }
                    .total-prices-linethrough {
                        text-decoration: line-through;
                    }
                    .line-divide {
                        margin: 0 10px;
                        border-left: #ddd solid 1px;
                    }
                    .quantity-btn {
                        // padding-top: 3px;
                        display: flex;
                        // align-items: center;
                        .minus-btn {
                            img {
                                cursor: pointer;
                                width: 23px;
                                height: 23px;
                            }
                        }
                        .input-qty {
                            input {
                                margin: 0 5px;
                                width: 50px;
                                text-align: center;
                                border-radius: 8px;
                                // height: 20px;
                                border: #000 solid 2px;
                            }
                        }
                    }
                    .print-btn-note {
                        button {
                            cursor: pointer;
                            border: none;
                            border-radius: 5px;
                            padding: 3px 0;
                            width: 100%;
                            text-align: center;
                            color: #1b4559;
                            font-size: 12px;
                            font-weight: 500;
                            outline: none;
                            background-color: transparent;
                        }
                    }
                    .print-btn-note:hover {
                        button {
                            cursor: pointer;
                            border: none;
                            border-radius: 5px;
                            padding: 3px 0;
                            width: 100%;
                            text-align: center;
                            color: #0d2029;
                            font-size: 12px;
                            font-weight: 500;
                            outline: none;
                            background-color: transparent;
                        }
                    }
                    .print-btn-status {
                        button {
                            // cursor: pointer;
                            border: none;
                            border-radius: 5px;
                            padding: 3px 0;
                            width: 100%;
                            text-align: center;
                            color: green;
                            font-size: 13px;
                            font-weight: 500;
                            outline: none;
                            background-color: transparent;
                        }
                    }
                    .print-btn-status:hover {
                        button {
                            // cursor: pointer;
                            border: none;
                            border-radius: 5px;
                            padding: 3px 0;
                            width: 100%;
                            text-align: center;
                            color: green;
                            font-size: 13px;
                            font-weight: 500;
                            outline: none;
                            background-color: transparent;
                        }
                    }
                    .received-status {
                        padding: 0 5px;
                        p {
                            font-size: 14px;
                            font-weight: 400;
                            color: green;
                        }
                    }
                    .total-qty-received {
                        h2 {
                            font-size: 14px;
                            font-weight: 500;
                            color: green;
                        }
                    }
                    .total-qty-linethrough {
                        text-decoration: line-through;
                    }
                }
            }
            .price {
                display: flex;
                align-items: center;
                .discounts {
                    width: 25px;
                }
                .discount {
                    background-color: #e8505b;
                    border-radius: 5px;
                    padding: 1px 5px;
                    h3 {
                        color: #fff;
                        font-size: 12px;
                        font-weight: 300;
                    }
                }
                .discount-amount {
                    background-color: #eaa24b;
                    border-radius: 5px;
                    padding: 2px 5px;
                    h3 {
                        color: #fff;
                        font-size: 11px;
                        font-weight: 300;
                    }
                }
                .title-price {
                    h1 {
                        color: #364153;
                        font-size: 15px;
                        font-weight: 500;
                    }
                }
                .title-discount {
                    display: flex;
                    align-items: center;
                    margin-left: 10px;
                    margin-right: 5px;
                    h2 {
                        color: #364153;
                        font-size: 14px;
                        font-weight: 300;
                        padding-right: 5px;
                        text-decoration: line-through;
                    }
                }
                .input-price {
                    border: #364153 solid 1px;
                    border-radius: 5px;
                    display: flex;
                    input {
                        width: 100px;
                        padding: 0 5px;
                        height: 22px;
                        border-radius: 5px;
                        outline: none;
                        border: none;
                    }
                    p {
                        padding-left: 7px;
                        color: #000;
                        font-weight: bold;
                        font-size: 15px;
                    }
                }
                .input-price-discount {
                    border: #364153 solid 1px;
                    border-radius: 5px;
                    margin: 0 5px;
                    display: flex;
                    input {
                        width: 100px;
                        padding: 0 5px;
                        height: 22px;
                        border-radius: 5px;
                        outline: none;
                        border: none;
                    }
                    p {
                        padding-left: 7px;
                        color: #000;
                        font-weight: bold;
                        font-size: 15px;
                    }
                }
            }
        }
    }
}

.proofpayment {
    display: flex;
    justify-content: center;
}

.input-shipping-cost {
    border: #364153 solid 1px;
    border-radius: 5px;
    margin: 5px 0;
    display: flex;
    align-items: center;
    width: 40%;
    input {
        width: 100%;
        padding: 0 5px;
        height: 30px;
        border-radius: 5px;
        outline: none;
        border: none;
        font-size: 17px;
    }
    p {
        padding-left: 7px;
        color: #000;
        font-weight: bold;
        font-size: 17px;
    }
}



.container-item-purchase {
    margin: 15px 3px;
    padding: 20px;
    background-color: #fff;
    box-shadow: #ddd 0px 0px 10px;
    border-radius: 10px;
    // height: 150px;
    // width: 100%;
    .top-div {
        padding: 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        .left {
            display: flex;
            flex-wrap: wrap;
            // align-items: center;
            p {
                font-size: 14px;
                color: #000;
                font-weight: 400;
                padding-bottom: 5px;
            }
            h6 {
                font-size: 15px;
                color: #023047;
                font-weight: 500;
                padding-bottom: 5px;
            }
            h5 {
                font-size: 15px;
                color: #000;
                font-weight: 400;
                padding-bottom: 5px;
            }
            img {
                width: 18px;
                height: 18px;
                margin-right: 5px;
                padding-bottom: 5px;
            }
            .line-right {
                border-right: #ddd 1px solid;
                margin: 0 10px;
            }

            @include breakpoints.media('<=tablet') {
                h6 {
                    font-size: 13px;
                }
                .line-right {
                    display: none !important;
                }
            }
        }
        .right {
            display: flex;
            align-items: center;
            padding-right: 5px;
            .status {
                padding: 3px 10px;
                border-radius: 5px;
                h6 {
                    font-size: 14px;
                    color: #fff;
                    font-weight: 400;
                }
            }
            img {
                cursor: pointer;
                width: 20px;
                height: 20px;
                margin-left: 5px;
                margin-right: 5px;
            }
        }
    }
    .center-div {
        padding: 5px 0;
        align-items: center;
        .left-box {
            h2 {
                font-size: 14px;
                color: #000;
                font-weight: 500;;
                padding-left: 5px;
            }
            .vendor-list {
                padding: 7px 0;
                display: flex;
                flex-wrap: wrap;
                .box-vendor {
                    border: #ddd 1px solid;
                    box-shadow: #ddd 0px 0px 10px;
                    padding: 5px 10px;
                    margin: 5px;
                    border-radius: 5px;
                    h3 {
                        text-transform: capitalize;
                        font-size: 15px;
                        color: #000;
                        font-weight: 400;
                    }
                }
                
            }
        }
        .total-prices {
            border-left: #ddd solid 1px;
            margin: 3px 0;
            padding: 15px;
            p {
                font-size: 14px;
                color: #000;
                font-weight: 300;
                padding-bottom: 5px;
            }
            h3 {
                font-size: 17px;
                color: #000;
                font-weight: bold;
                margin: 0;
            }
        }
    }
    .bottom-div {
        margin-top: 5px;
        padding: 0 10px;
        display: flex;
        justify-content: space-between;    
        align-items: center;
        .btn-pr-submit {
            margin: 0 5px;
            button {
                cursor: pointer;
                background-color: #fff;
                border: #43a047 solid 1px;
                box-shadow: #ddd 0px 0px 10px;
                border-radius: 5px;
                padding: 8px 20px;
                transition: all 0.3s ease;
                width: 100%;
                text-align: center;
                color: #43a047;
                font-size: 14px;
                font-weight: 500;
            }
            :disabled {
                background-color: #ddd;
                border: #ddd solid 1px;
                color: rgb(168, 165, 165);
                cursor: not-allowed;
            }
        }
        .btn-pr-submit:hover {
            margin: 0 5px;
            button {
                cursor: pointer;
                background-color: #43a047;
                border: #fff solid 1px;
                box-shadow: #ddd 0px 0px 10px;
                border-radius: 5px;
                padding: 8px 20px;
                width: 100%;
                text-align: center;
                color: #fff;
                font-size: 14px;
                font-weight: 500;
            }
            :disabled {
                background-color: #ddd;
                color: rgb(168, 165, 165);
                cursor: not-allowed;
            }
        }
        .btn-pr-reject {
            margin: 0 5px;
            button {
                cursor: pointer;
                background-color: #fff;
                border: #e8505b solid 1px;
                box-shadow: #ddd 0px 0px 10px;
                border-radius: 5px;
                padding: 8px 20px;
                transition: all 0.3s ease;
                width: 100%;
                text-align: center;
                color: #e8505b;
                font-size: 14px;
                font-weight: 500;
            }
            :disabled {
                background-color: #ddd;
                border: #ddd solid 1px;
                color: rgb(168, 165, 165);
                cursor: not-allowed;
            }
        }
        .btn-pr-reject:hover {
            margin: 0 5px;
            button {
                cursor: pointer;
                background-color: #e8505b;
                border: #fff solid 1px;
                box-shadow: #ddd 0px 0px 10px;
                border-radius: 5px;
                padding: 8px 20px;
                width: 100%;
                text-align: center;
                color: #fff;
                font-size: 14px;
                font-weight: 500;
            }
            :disabled {
                background-color: #ddd;
                color: rgb(168, 165, 165);
                cursor: not-allowed;
            }
        }
        .view-btn {
            cursor: pointer;
            p {
                font-size: 14px;
                color: #43a047;
                font-weight: 500;
                padding: 0 5px;
            }
        }
        .view-btn-pdf {
            cursor: pointer;
            p {
                font-size: 14px;
                color: #023047;
                font-weight: 500;
                padding: 0 5px;
            }
        }
        .line-divider {
            width: 1px;
            height: 20px;
            margin: 6px 10px;
            background: #ddd;
        }
    }
}



.history-status {
    max-height: 400px;
    overflow-x: auto;
    .content {
        background-color: #fff;
        border-radius: 8px;
        box-shadow: #ddd 0px 0px 10px;
        margin: 5px 5px;
        padding: 10px;
        .top {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
            p {
                font-size: 14px;
                color: #000;
                font-weight: 400;
                padding-bottom: 5px;
            }
            h5 {
                min-width: 70px;
                font-size: 15px;
                color: #000;
                font-weight: 400;
                padding-bottom: 5px;
                font-style: normal;
            }
            img {
                width: 18px;
                height: 18px;
                margin-right: 5px;
                padding-bottom: 5px;
            }
        }
        h6 {
            font-size: 14px;
            color: #000;
            font-weight: 400;
            padding-bottom: 5px;
        }
        h5 {
            min-width: 70px;
            font-size: 14px;
            color: #000;
            font-weight: 400;
            padding-bottom: 5px;
        }
    }
    h4 {
        font-size: 16px;
        color: #000;
        font-weight: 400;
        padding-bottom: 5px;
    }
}