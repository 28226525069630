@use './breakpoints/breakpoints.scss';

body {
    font-family: "Roboto", sans-serif;
    margin: 0;
    padding: 0;
}

h1, h2, h3, h4, h5, h6, p, body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
}

a {
    text-decoration: none !important;
}


::-webkit-scrollbar {
    width: 0.7em;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
    background-color: #dddddd;
    outline: 1px solid #b3b3b3;
}

.overhiddenflow ::-webkit-scrollbar {
    display: none;
}
.global-container {
    padding-left: 10px;
    padding-right: 10px;
}

.container-height {
    margin-top: 20px;
    padding-top: 40px;
    min-height: 70vh;
    margin-bottom: 50px;
    h1 {
        color: #0091d6;
    }
}

.react-multi-email-input {
    input {
        width: 100% !important;
    }
}

.hover-hints {
    // padding: 2px 5px;
    h6 {
        color: #364153;
        font-size: 12px;
        font-weight: 400;
        padding: 3px 0;
    }
}

.img-info {
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin: 0 5px;
}

.title-hints-info {
    display: flex;
}

.button-auth {
    margin: 15px 0;
    button {
        cursor: pointer;
        background-color: #0091d6;
        border: #fff solid 1px;
        box-shadow: #ddd 0px 0px 10px;
        border-radius: 9px;
        padding: 10px 17px;
        width: 100%;
        text-align: center;
        transition: all 0.3s ease;
        color: #fff;
        font-size: 16px;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    :disabled {
        background-color: #ddd;
        color: rgb(168, 165, 165);
        cursor: not-allowed;
        border: none;
        outline: none;
    }
}
.button-auth:hover {
    button {
        cursor: pointer;
        background-color: #0c709e;
        border: #fff solid 1px;
        box-shadow: #ddd 0px 0px 10px;
        border-radius: 9px;
        padding: 10px 17px;
        width: 100%;
        text-align: center;
        color: #fff;
        font-size: 16px;
        font-weight: 500;
    }
    :disabled {
        background-color: #ddd;
        color: rgb(168, 165, 165);
        cursor: not-allowed;
        border: none;
        outline: none;
    }
}

.container-box-auth {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    background-color: #f9fafc;
    background-size: cover;
    height: 100vh;
    .paper-box {
        margin: 10px;
        background-color: #fff;
        box-shadow: #ddd 0 0 10px;
        border-radius: 15px;
        width: fit-content;
        height: fit-content;
        padding: 4rem 2rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: fit-content;
        img {
            width: 150px;
            margin: 5px 0;
        }
        .name-login {
            text-align: center;
            padding-bottom: 1rem;
            h1 {
                padding-bottom: 5px;
                color: #0091d6;
                font-weight: 500;
                font-size: 28px;
            }
            p {
                color: #0091d6;
                font-weight: 400;
            }
        }
        .form-auth {
            margin-top: 1rem;
            width: 400px;
            h2 {
                text-align: left;
                font-size: 15px;
                color: #0091d6;
                font-weight: 400;
                letter-spacing: 0.5px;
            }
            .button-auth {
                margin: 15px 0;
                button {
                    cursor: pointer;
                    background-color: #0091d6;
                    border: #fff solid 1px;
                    box-shadow: #ddd 0px 0px 10px;
                    border-radius: 9px;
                    padding: 10px 17px;
                    width: 100%;
                    text-align: center;
                    transition: all 0.3s ease;
                    color: #fff;
                    font-size: 16px;
                    font-weight: 500;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                :disabled {
                    background-color: #ddd;
                    color: rgb(168, 165, 165);
                    cursor: not-allowed;
                    border: none;
                    outline: none;
                }
            }
            .button-auth:hover {
                button {
                    cursor: pointer;
                    background-color: #0f80b4;
                    border: #fff solid 1px;
                    box-shadow: #ddd 0px 0px 10px;
                    border-radius: 9px;
                    padding: 10px 17px;
                    width: 100%;
                    text-align: center;
                    color: #fff;
                    font-size: 16px;
                    font-weight: 500;
                }
                :disabled {
                    background-color: #ddd;
                    color: rgb(168, 165, 165);
                    cursor: not-allowed;
                    border: none;
                    outline: none;
                }
            }
        }
        @include breakpoints.media('<=phone') {
            .form-auth {
                margin-top: 1rem;
                width: 280px;
            }
            .name-login {
                h1 {
                    font-size: 23px;
                }
                p {
                    font-size: 15px;
                }
            }
        }
        @include breakpoints.media('>phone', '<=tablet') {
            .form-auth {
                margin-top: 1rem;
                width: 280px;
            }
            .name-login {
                h1 {
                    font-size: 24px;
                }
                p {
                    font-size: 15px;
                }
            }
        }
    }
}



.container-box-relogin {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    background-color: #f9fafc;
    background-size: cover;
    height: 90vh;
    border-radius: 10px;

}


.ck.ck-editor__main>.ck-editor__editable {
    min-height: 170px;
}

.container-footer {
    width: 100%;
}

.copyright-text {
    text-align: center;
    color: #092c4c;
}

.error-p {
    color: #d43232;
    padding-left: 5px;
    padding-top: 0px;
    font-size: 13px;
}

.box-modal-create {
    min-height: 500px;
    margin-bottom: 20px;
    padding: 15px;
}

.box-modal-modules {
    min-height: 500px;
    margin-bottom: 20px;
    padding: 15px;
}

#select-style-warehouse-0 {
    z-index: 150 !important
}

#select-style-warehouse-1 {
    z-index: 145 !important
}

#select-style-warehouse-2 {
    z-index: 140 !important
}

#select-style-warehouse-3 {
    z-index: 135 !important
}

#select-style-warehouse-4 {
    z-index: 130 !important
}

#select-style-warehouse-5 {
    z-index: 125 !important
}

#select-style-warehouse-6{
    z-index: 120 !important
}

#select-style-warehouse-7 {
    z-index: 110 !important
}

#select-style-warehouse-8 {
    z-index: 105 !important
}

#select-style-warehouse-9 {
    z-index: 100 !important
}



#select-style-warehouse-type-0 {
    z-index: 149 !important
}

#select-style-warehouse-type-1 {
    z-index: 144 !important
}

#select-style-warehouse-type-2 {
    z-index: 139 !important
}

#select-style-warehouse-type-3 {
    z-index: 134 !important
}

#select-style-warehouse-type-4 {
    z-index: 129 !important
}

#select-style-warehouse-type-5 {
    z-index: 124 !important
}

#select-style-warehouse-type-6{
    z-index: 119 !important
}

#select-style-warehouse-type-7 {
    z-index: 114 !important
}

#select-style-warehouse-type-8 {
    z-index: 104 !important
}

#select-style-warehouse-type-9 {
    z-index: 88 !important
}


#select-style-value {
    z-index: 150 !important
}

#select-style-cat {
    z-index: 250 !important;
}

#select-style-type {
    z-index: 240 !important;
}

#select-style-province {
    z-index: 30 !important;
}

#select-style-city {
    z-index: 20 !important;
    text-transform: capitalize;
}

#select-style-district {
    z-index: 10 !important;
    text-transform: capitalize;
}

#select-style-countryy {
    z-index: 99 !important;
}


#select-style-provincee {
    z-index: 98 !important;
}

#select-style-cityy {
    z-index: 97 !important;
}


#select-style-subdistrict {
    z-index: 95 !important;
}

#select-style-village {
    z-index: 93 !important;
}

#select-style-zipcode {
    z-index: 92 !important;
}
#select-style-cat-product-0 {
    z-index: 55 !important;
}
#select-style-cat-product-1 {
    z-index: 50 !important;
}
#select-style-cat-product-2 {
    z-index: 49 !important;
}
#select-style-cat-product-3 {
    z-index: 48 !important;
}
#select-style-cat-product-4 {
    z-index: 47 !important;
}
#select-style-cat-product-5 {
    z-index: 46 !important;
}
#select-style-cat-product-6 {
    z-index: 45 !important;
}

#select-style-brand-product {
    z-index: 40 !important;
}

#select-style-mes-product {
    z-index: 30 !important;
}

#select-style-warehouse-product {
    z-index: 20 !important;
}

#select-roles {
    z-index: 10 !important;
}


#select-style-coverage-0 {
    z-index: 800 !important;
}

#select-style-coverage-1 {
    z-index: 900 !important;
}
#select-style-coverage-2 {
    z-index: 1000 !important;
}
#select-style-coverage-3 {
    z-index: 1100 !important;
}
#select-style-coverage-4 {
    z-index: 1200 !important;
}
#select-style-coverage-5 {
    z-index: 1300 !important;
}

#select-style-coverage-6 {
    z-index: 1400 !important;
}
#select-style-coverage-7 {
    z-index: 1500 !important;
}
#select-style-coverage-9 {
    z-index: 1600 !important;
}

#select-style-warehouse-product-0 {
    z-index: 100 !important;
}
#select-style-warehouse-product-1 {
    z-index: 99 !important;
}
#select-style-warehouse-product-2 {
    z-index: 98 !important;
}
#select-style-warehouse-product-3 {
    z-index: 97 !important;
}
#select-style-warehouse-product-4 {
    z-index: 96 !important;
}
#select-style-warehouse-product-5 {
    z-index: 95 !important;
}
#select-style-warehouse-product-6 {
    z-index: 94 !important;
}
#select-style-warehouse-product-7 {
    z-index: 93 !important;
}
#select-style-warehouse-product-9 {
    z-index: 902 !important;
}
#select-style-warehouse-product-10 {
    z-index: 900 !important;
}
#select-style-warehouse-product-11 {
    z-index: 809 !important;
}
#select-style-warehouse-product-12 {
    z-index: 088 !important;
}
#select-style-warehouse-product-13 {
    z-index: 807 !important;
}
#select-style-warehouse-product-14 {
    z-index: 806 !important;
}
#select-style-warehouse-product-15 {
    z-index: 805 !important;
}
#select-style-warehouse-product-16 {
    z-index: 804 !important;
}
#select-style-warehouse-product-17 {
    z-index: 803 !important;
}
#select-style-warehouse-product-18 {
    z-index: 802 !important;
}
#select-style-warehouse-product-19 {
    z-index: 801 !important;
}
#select-style-warehouse-product-20 {
    z-index: 800 !important;
}

#product-notfound {
    background-color: #fff;
    box-shadow: #ddd 0px 0px 10px;
    border-radius: 7px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 30px 15px;
    img {
        width: 100px;
        height: 100px;
    }
    p {
        font-size: 15px;
    }
    h6 {
        font-size: 16px;
        color: #0091d6;
        font-weight: bold;
        padding-bottom: 5px;
    }
}

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  }

  /* Firefox */
  input[type=number] {
  -moz-appearance: textfield;
  }


#ce-add-username-input {
    display: none !important;
}

#ce-options-drop-down {
    display: none !important;
}
#ce-delete-chat-button {
    display: none !important;
}

#new-chat-plus-button {
	display: none !important;
}

.ce-chat-form-container {
    margin-bottom: 35px !important;
}

#img-review-proof {
    cursor: pointer;
}



.btn_error {
	button {
		background-color: #e8505b !important;
	}
}

.btn_error:hover {
	button {
		background-color: #be0915 !important;
	}
}

.btn_outlined {
	button {
		background-color: #fff !important;
		border: #0091d6 solid 1px !important;
		color: #0091d6 !important;
	}
}
.button-action {
	button {
		cursor: pointer;
		background-color: #0091d6;
		border: #fff solid 1px;
		box-shadow: #ddd 0px 0px 10px;
		border-radius: 11px;
		padding: 7px 17px;
		width: 100%;
		text-align: center;
		transition: all 0.3s ease;
		color: #fff;
		font-size: 13px;
		font-weight: 400;
	}
	:disabled {
		background-color: #ddd;
		color: rgb(168, 165, 165);
		cursor: not-allowed;
		border: none;
		outline: none;
	}
}
.button-action:hover {
	button {
		cursor: pointer;
		background-color: #09658f;
		border: #fff solid 1px;
		box-shadow: #ddd 0px 0px 10px;
		border-radius: 11px;
		padding: 7px 17px;
		width: 100%;
		text-align: center;
		color: #fff;
		font-size: 13px;
		font-weight: 400;
	}
	:disabled {
		background-color: #ddd;
		color: rgb(168, 165, 165);
		cursor: not-allowed;
		border: none;
		outline: none;
	}
}


.not-found-data {
	min-height: 320px;
	// width: 95%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: #fff;
	border-radius: 10px;
	padding: 1rem 10px;
	box-shadow: #ddd 0 0 10px;
	margin: 1px;
	text-align: center;
	h1 {
		font-size: 1.2rem;
		font-weight: 500;
		margin: 0;
	}
	p {
		font-size: 0.8rem;
		margin: 0;
		font-weight: 400;
	}
	img {
		margin-bottom: 1rem;
		width: 100px;
		height: 100px;
		object-fit: cover;
	}
}


.invitation-container {
	margin: 1rem 0;
	background-color: #fff;
	border: #fff solid 1px;
	box-shadow: #ddd 0px 0px 10px;
	padding: 2rem 1.5rem;
	border-radius: 12px;
	h4 {
		font-size: 16px;
		font-weight: 500;
		margin-bottom: 0.5rem;
	}
	.action {
		display: flex;
		justify-content: flex-end;
		margin-top: 2rem;
	}

}


.image-gallery {
    margin: 10px;
    display: grid;
    flex-wrap: wrap;
    flex-direction: row;
    // grid-template-columns: repeat(auto-fit, minmax(50px, 1fr 1fr 1fr 1fr));
    grid-gap: 0.5rem;
    margin: -1rem;
    // max-width: fit-content;
    &__item {
        background-color: #ccd7df;
        border-radius: 5px;
        // text-align: center;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding-top: 2px;
      
      &__name {
            font-size: 1rem;
            font-weight: bold;
            // max-width: fit-content;
            // max-width: 200px;
            // max-height: 200px;
            padding-right: 10px;
            margin-top: 5px;
        }
        
        &__image {
        max-width: 200px;
        max-height: 200px;
        margin: 5px;
        border-radius: 5px;
        flex: 1;
      }
    }
  
    // @media (min-width: 768px) {
    //   grid-template-rows: repeat(auto-fill, minmax(100px, 1fr));
    //   grid-auto-flow: dense;
  
    //   &__item:nth-child(3n+1) {
    //     grid-row: span 2;
    //   }
  
    //   &__item:nth-child(3n+2) {
    //     grid-row: span 3;
    //   }
  
    //   &__item:nth-child(3n+3) {
    //     grid-row: span 3;
    //   }
    // }
  }

  .media-center {
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // flex-direction: row;
    // margin: 1rem;
    margin: 5px;
    // position: absolute;
    .image-container {
        position: relative;
        background-color: #ccd7df;
        cursor: pointer;
        // width: 200px;
        height: 250px;
        border-radius: 5px;
        img {
            border-radius: 5px;
            padding: 5px;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        .file-name {
            display: none;
        }
        .btn-download {
            display: none;
        }
    }
    .image-container:hover {
        // opacity: 0.8;
        .file-name {
            background-color: #0091d6;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            width: 100%;
            display: block;
            position: absolute;
            // top: 0;
            padding: 5px 10px;
            top: 0;
            // padding: 5px;
            p {
                color: #fff;
                font-size: 0.8rem;
                font-weight: 500;
                margin: 0;
            }
        }
        .btn-download {
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            width: 100%;
            display: block;
            position: absolute;
            // top: 0;
            // padding: 5px 10px;
            padding: 10px;
            bottom: 0;
            // padding: 5px;
            p {
                color: #fff;
                font-size: 0.8rem;
                font-weight: 500;
                margin: 0;
            }
            display: flex;
            justify-content: space-between;
            .download {
                background-color: #0091d6;
                padding: 2px 8px;
                border-radius: 5px;
            }
            .copy {
                background-color: #0091d6;
                padding: 2px 8px;
                border-radius: 5px;
            }
        }
    }
}