@use '../breakpoints/breakpoints.scss';

.section-form-stepper {
    padding-top: 25px;
}

.section-top {
    padding-left: 20px;
    padding-top: 10px;
}

.img-warehouse {
    display: flex;
    align-items: center;
    img {
        height: 100px;
        width: 100px;
        border-radius: 50%;
    }
}

.upload-btn-warehouse {
    cursor: pointer !important;
    display: flex;
    align-items: center;
    padding-left: 20px;
}

.btn-label-warehouse {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.detail-warehouse {
    display: flex;
    flex-direction: column;
    h6 {
        font-size: 20px;
    }
    p {
        font-size: 15px;
    }
    padding-left: 20px;
}

.item-coverage {
    display: flex;
    flex-direction: row;
    text-transform: capitalize;
    flex-wrap: wrap;
}

.store-banner {
    width: 100%;
    padding : 15px;
    margin-bottom: 25px;
    position: relative;
    top: 0;
    left: 0;
    .img-banner {
        position: relative;
        img {
            width: 100%;
            height : 300px;
            border-radius: 15px;
        }
        top: 0;
        left: 0;
        .upload-btn-banner {
            position: relative;
            border-radius: 15px;
            cursor: pointer !important;
            right: 10px;
            top: 10px;
            padding: 10px;
            display: flex;
            align-items: center;
            h4 {
                padding-right: 4px;
                cursor: pointer;
            }
        }
    }
    .img-store {
        position: absolute;
        width: fit-content;
        left: 40px;
        top: 210px;
        width: 100%;
        height: 100%;
        img {
            height: 150px;
            width: 150px;
            border-radius: 50%;
        }
        .upload-btn-store {
            position: absolute;
            left: 80px;
            top: 110px;
            padding: 10px;
            cursor: pointer;
        }
    }

    @include breakpoints.media('<=phone') {
        .img-store {
            width: 100%;
            height: 100%;
            img {
                height: 150px;
                width: 150px;
                border-radius: 50%;
            }
            .upload-btn-store {
                position: absolute;
                left: 80px;
                top: 10px;
                padding: 10px;
            }
        }
        .container-page {
            padding: 10px;
        }
    }
    @include breakpoints.media('>phone', '<=tablet') {
        .img-banner {
            img {
                width: 100%;
                height : 100px;
            }
            .upload-btn-banner {
                position: absolute;
                cursor: pointer !important;
                right: 10px;
                bottom: 0px;
                padding: 10px;
                display: flex;
                align-items: center;
                h4 {
                    padding-right: 4px;
                    cursor: pointer;
                }
            }
        }
        .img-store {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 40px;
            top: 75px;
            img {
                height: 70px;
                width: 70px;
                border-radius: 50%;
            }
            .upload-btn-store {
                position: absolute;
                left: 20px;
                top: 36px;
                padding: 10px;
            }
        }
    }
    @include breakpoints.media('>tablet', '<=desktop') {
        .container-page {
            padding: 35px;
        }
        .img-banner {
            img {
                width: 100%;
                height : 200px;
            }
            .upload-btn-banner {
                position: absolute;
                cursor: pointer !important;
                right: 10px;
                bottom: 0px;
                padding: 10px;
                display: flex;
                align-items: center;
                h4 {
                    padding-right: 4px;
                    cursor: pointer;
                }
            }
        }
        .img-store {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 40px;
            top: 125px;
            img {
                height: 130px;
                width: 130px;
                border-radius: 50%;
            }
            .upload-btn-store {
                position: absolute;
                left: 70px;
                top: 90px;
                padding: 10px;
            }
        }
    }
    @include breakpoints.media('>desktop') {
        .container-page {
            padding: 35px;
        }
        .img-banner {
            img {
                width: 100%;
                height : 300px;
            }
            .upload-btn-banner {
                position: absolute;
                cursor: pointer !important;
                right: 10px;
                bottom: 0px;
                padding: 10px;
                display: flex;
                align-items: center;
                h4 {
                    padding-right: 4px;
                    cursor: pointer;
                }
            }
        }
        .img-store {
            width: 100%;
            height: 100%;
            img {
                height: 150px;
                width: 150px;
                border-radius: 50%;
            }
        }
    }
    
}



// company banner
.company-banner {
    // width: 100%;
    background-color: white;
    border-radius: 15px;
    padding-bottom: 30px;
    .img-banner {
        img {
            width: 100%;
            height : 250px;
            border-radius: 15px;
            // border: 1px solid #0091d6;
        }
        .img-banner-skeleton {
            margin-top: 0px;
            height : 250px;
            border-radius: 15px;
            background-color: #ccd7df;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
        .upload-btn-banner {
            border-radius: 15px;
            cursor: pointer !important;
            margin-top: -65px;
            padding-right: 15px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            h4 {
                padding-right: 4px;
                cursor: pointer;
            }
        }
    }
   
    .img-store {
        height: 100%;
        margin-top: -50px;
        margin-left: 40px;
        z-index: 200px;
        img {
            height: 170px;
            width: 170px;
            border-radius: 50%;
        }
        .img-store-skeleton {
            width: 170px;
            height: 170px;
            background-color: #ccd7df;
            border-radius: 50%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

        }
        .upload-btn-store {
            z-index: 100px;
            margin-top: -40px;
            margin-left: 90px;
            cursor: pointer;
        }
    }

    .side-name {
        padding-left: 15px;
        padding-top: 25px;
        img {
            padding-top: 5px;
            width: 25px;
            padding-right: 5px;
        }
    }
    .side-name-skeleton {
        padding-left: 15px;
        margin-top: 25px;
        img {
            padding-top: 5px;
            width: 25px;
            padding-right: 5px;
        }
    }


    @include breakpoints.media('<=phone') {
        .img-banner {
            img {
                width: 100%;
                height : 150px;
                border-radius: 15px;
            }
            .upload-btn-banner {
                border-radius: 15px;
                cursor: pointer !important;
                margin-top: -65px;
                padding-right: 15px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                h4 {
                    padding-right: 4px;
                    cursor: pointer;
                }
            }
        }
        .img-store {
            height: 100%;
            margin-top: -50px;
            margin-left: 20px;
            z-index: 100px;
            img {
                height: 100px;
                width: 100px;
                border-radius: 50%;
            }
            .upload-btn-store {
                z-index: 100px;
                margin-top: -40px;
                margin-left: 90px;
                cursor: pointer;
            }
        }
    
        .side-name {
            padding-left: 15px;
            padding-top: 25px;
            img {
                padding-top: 5px;
                width: 25px;
                padding-right: 5px;
            }
        }
    }
    @include breakpoints.media('>phone', '<=tablet') {
        .container-page {
            padding: 25px;
        }
        .img-banner {
            img {
                width: 100%;
                height : 150px;
                border-radius: 10px;
            }
            .upload-btn-banner {
                border-radius: 15px;
                cursor: pointer !important;
                margin-top: -65px;
                padding-right: 15px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                h4 {
                    padding-right: 4px;
                    cursor: pointer;
                }
            }
        }
        .img-store {
            height: 100%;
            margin-top: -20px;
            margin-left: 20px;
            z-index: 100px;
            img {
                height: 100px;
                width: 100px;
                border-radius: 50%;
            }
            .upload-btn-store {
                z-index: 100px;
                margin-top: -40px;
                margin-left: 60px;
                cursor: pointer;
            }
        }
    
        .side-name {
            padding-left: 15px;
            padding-top: 15px;
            img {
                padding-top: 5px;
                width: 20px;
                padding-right: 5px;
            }
            h2 {
                font-size: 14px;
                font-weight: 600;
            }
            h4 {
                font-size: 12px;
                font-weight: 400;
            }
            p {
                font-size: 10px;
            }
        }
    }
    @include breakpoints.media('>tablet', '<=desktop') {
        .container-page {
            padding: 35px;
        }
        .img-banner {
            img {
                width: 100%;
                height : 200px;
                border-radius: 10px;
            }
            .upload-btn-banner {
                border-radius: 15px;
                cursor: pointer !important;
                margin-top: -65px;
                padding-right: 15px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                h4 {
                    padding-right: 4px;
                    cursor: pointer;
                }
            }
        }
        .img-store {
            height: 100%;
            margin-top: -20px;
            margin-left: 20px;
            z-index: 100px;
            img {
                height: 130px;
                width: 130px;
                border-radius: 50%;
            }
            .upload-btn-store {
                z-index: 100px;
                margin-top: -40px;
                margin-left: 90px;
                cursor: pointer;
            }
        }
    
        .side-name {
            padding-left: 15px;
            padding-top: 20px;
            img {
                padding-top: 5px;
                width: 25px;
                padding-right: 5px;
            }
            h2 {
                font-size: 21px;
                font-weight: 600;
            }
            h4 {
                font-size: 16px;
                font-weight: 400;
            }
            p {
                font-size: 13px;
            }
        }
    }
    @include breakpoints.media('>desktop') {
        .container-page {
            padding: 35px;
        }
        .img-banner {
            img {
                width: 100%;
                height : 250px;
                border-radius: 15px;
            }
            .upload-btn-banner {
                border-radius: 15px;
                cursor: pointer !important;
                margin-top: -65px;
                padding-right: 15px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                h4 {
                    padding-right: 4px;
                    cursor: pointer;
                }
            }
        }
        .img-store {
            height: 100%;
            margin-top: -50px;
            margin-left: 40px;
            z-index: 100px;
            img {
                height: 170px;
                width: 170px;
                border-radius: 50%;
            }
            .upload-btn-store {
                z-index: 100px;
                margin-top: -40px;
                margin-left: 110px;
                cursor: pointer;
            }
        }
    
        .side-name {
            padding-left: 15px;
            padding-top: 25px;
            img {
                padding-top: 5px;
                width: 25px;
                padding-right: 5px;
            }
        }
    }
    
}


// tab

.tab-profile {
    background-color: white;
    border-radius: 15px;
    width: 100%;
    .container-tab {
        margin: 15px;
    }
    .tabs-profile {
        padding: 7px 18px;
        border-radius: 10px;
        margin: 5px;
        border: 1px solid #707070;
        background-color: #fff;
        cursor: pointer;
        p {
            font-size: 16px;
            font-weight: 400;
            color: #000;
        }
    }
    .active-tabs-profile {
        padding: 7px 18px;
        border-radius: 10px;
        margin: 5px;
        border: 1px solid #0091d6;
        background-color: #0091d6;
        cursor: pointer;
        p {
            font-size: 16px;
            font-weight: 400;
            color: #fff;
        }
    }
    @include breakpoints.media('<=tablet') {
        .tabs-profile {
            padding: 5px 10px;
            margin: 5px;
            p {
                font-size: 14px;
                font-weight: 400;
            }
        }
        .active-tabs-profile {
            padding: 5px 10px;
            margin: 5px;
            p {
                font-size: 14px;
                font-weight: 400;
            }
        }
    }
}