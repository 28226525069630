.dropzone {
    height: 200px;
    width: 100%;
    border: 2px dashed #ccc;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
  
    &.isDragActive {
      border-color: #3f51b5;
    }
  
    .dragText {
      font-size: 24px;
      margin: 0;
      color: #3f51b5;
    }
  
    .selectText {
      font-size: 18px;
      margin: 0 0 10px;
    }
  
    .supportedText {
      font-size: 14px;
      margin: 0;
      color: #666;
    }
  }
  

  .imageList {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-gap: 20px;
  }
  
  .imageWrapper {
    position: relative;
  }
  
  .image {
    width: 100%;
    height: auto;
    display: block;
    border-radius: 5px;
  }
  
  .filename {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 5px;
    color: #fff;
    border-radius: 0 0 5px 5px;
  
    p {
      margin: 0;
      font-size: 14px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
  

  